import dynamic from "next/dynamic"
import { Suspense } from "react"
import type { YouTubeProps } from "react-youtube"

const Youtube = dynamic(() => import("react-youtube"))

export default function YoutubePlayer(props: YouTubeProps) {
  return (
    <Suspense fallback={null}>
      <Youtube {...props} />
    </Suspense>
  )

}