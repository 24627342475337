import styled from "@emotion/styled"
import * as Dialog from "@radix-ui/react-dialog"
import { FaTimes } from "@react-icons/all-files/fa/FaTimes"
import { Value } from "@stringtale/react"
import YoutubePlayer from "app/content-blocks/components/YoutubePlayer"
import { ReactNode } from "react"
import { DARK_BLUE_COLOR } from "theme/colors"
import { PX24 } from "theme/sizes"
import { Close, Content, Overlay, Root as RootBase } from "ui/Modal"
import Stack from "ui/Stack"
import Title from "ui/Title"

const Root = styled(RootBase)`
  aspect-ratio: 16 / 9;
`

const VideoContainer = styled.div`
  width: 100%;
  min-height: 205px;
  max-height: 360px;
  position: relative;
  /* border-radius: 12px; */
  overflow: hidden;
`

const Player = styled(YoutubePlayer)`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

export default function YoutubeModal({
  title,
  videoId,
}: {
  title?: ReactNode
  videoId: string
}) {
  return (
    <Dialog.Portal>
      <Dialog.Overlay asChild>
        <Overlay />
      </Dialog.Overlay>
      <Dialog.Content>
        <Root width="800px">
          <Content>
            <Stack gap="16px">
              {title && <Title size={PX24}>{title}</Title>}
              <Stack gap="24px">
                <VideoContainer>
                  <Player
                    videoId={videoId}
                    opts={{
                      width: "100%",
                    }}
                  />
                </VideoContainer>
              </Stack>
            </Stack>
          </Content>
          <Dialog.Close asChild>
            <Close>
              <FaTimes color={DARK_BLUE_COLOR} size="20px" />
            </Close>
          </Dialog.Close>
        </Root>
      </Dialog.Content>
    </Dialog.Portal>
  )
}
