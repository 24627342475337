import { useMutation, useQuery } from "@blitzjs/rpc"
import { Value, htmlFormatTags } from "@stringtale/react"
import { GroupSelectItem } from "app/groups/components/GroupSelectItem"
import SelectGroupModal from "app/groups/components/SelectGroupModal"
import { PX14 } from "theme/sizes"
import Hr from "ui/Hr"
import * as Modal from "ui/Modal"
import Stack from "ui/Stack"
import Text from "ui/Text"
import migrateGroups from "../mutations/migrateGroups"
import getGroupsInSchool from "app/auth/queries/getGroupsInSchool"
import * as Dialog from "@radix-ui/react-dialog"
import * as AlertDialog from "@radix-ui/react-alert-dialog"
import RoundedButton from "ui/RoundedButton"
import { useMemo, useState } from "react"
import AlertModal from "ui/AlertModal"
import useToast from "app/toasts/hooks/useToast"
import { ORANGE_COLOR } from "theme/colors"
import { useRouter } from "next/router"
import useLinks from "app/core/hooks/useLinks"
import { useCurrentUser } from "app/core/hooks/useCurrentUser"
import { useCurrentOrganization } from "app/core/hooks/useCurrentOrganization"
import { isManager } from "app/users/utils/isManager"

type Props = {
  onClose: () => void
  groupId: number
}

export default function MigrateGroupsModal({
  onClose,
  groupId,
  ...props
}: Props) {
  const [migrateGroupsMutation] = useMutation(migrateGroups)
  const currentUser = useCurrentUser()
  const currentOrganization = useCurrentOrganization()
  const isManagerOfSchool = useMemo(() => {
    return isManager({ currentUser, organizationId: currentOrganization?.id })
  }, [currentOrganization, currentUser])

  const [groups] = useQuery(getGroupsInSchool, { all: isManagerOfSchool })
  const [isMigrateDialogOpen, setIsMigrateDialogOpen] = useState(false)

  const addToast = useToast()

  const router = useRouter()
  const getLink = useLinks()

  const submit = async (groupIds: number[]) => {
    const result = await migrateGroupsMutation({
      fromGroupId: groupId,
      toGroupIds: groupIds,
    })

    setIsMigrateDialogOpen(false)

    if (!result) {
      addToast({
        content: (
          <Value
            format={{
              link: (c) => (
                <Text
                  underline
                  bold
                  color={ORANGE_COLOR}
                  onClick={async () => {
                    let query = {
                      ...router.query,
                      bibliotheek: "",
                    }
                    delete query["archief"]
                    router.push(
                      getLink({
                        pathname: router.pathname,
                        query,
                      })
                    )
                  }}
                >
                  {c}
                </Text>
              ),
            }}
            name="apps.web.src.themes.components.migrategroupmodal.allles_is_gekopieerd"
          >
            {`Alles is gekopieerd naar de <link>bibliotheek</link>.`}
          </Value>
        ),
      })
    } else {
      addToast({
        content: (
          <Value name="apps.web.src.themes.components.migrategroupmodal.alles_gekopieerd_geen_toegang">
            {`Alles is gekopieerd naar de geselecteerde groep(en).`}
          </Value>
        ),
      })
    }

    onClose()
  }

  // If you have a single group, use this
  if (groups.length === 1) {
    return (
      <AlertDialog.Root
        open={isMigrateDialogOpen}
        onOpenChange={setIsMigrateDialogOpen}
      >
        <AlertDialog.Trigger asChild>
          <RoundedButton variant={"filled"} color="blue">
            <Value
              name="apps.web.src.schooljaren.components.archivemodal.alles_kopieren"
              version="1"
            >
              Kopieer alles naar huidig schooljaar
            </Value>
          </RoundedButton>
        </AlertDialog.Trigger>
        <AlertModal
          title={
            <Modal.Title>
              <Value name="apps.web.src.schooljaren.components.migrategroupmodal.kopieer_alles_naar_de_bibliotheek_van_huidig_schooljaar">
                Kopieer alles naar de bibliotheek van huidig schooljaar
              </Value>
            </Modal.Title>
          }
          body={
            <Value
              name="apps.web.src.schooljaren.components.migrategroupmodal.body_single_group"
              format={{ groupName: groups[0].displayTitle, ...htmlFormatTags }}
            >
              {`Wil je alle opgeslagen lijsten kopiëren naar de bibliotheek van het huidige schooljaar van {groupName}?{br}{br}<b>Let op:</b> De huidige kidslijst wordt overgeschreven 
              met de kidslijst uit het archief.`}
            </Value>
          }
          submit={
            <Modal.SubmitButton
              onClick={async () => {
                await submit([groups[0].id])
              }}
            >
              <Value name="apps.web.src.schooljaren.components.migrategroupmodal.bevestig">
                Bevestig
              </Value>
            </Modal.SubmitButton>
          }
          cancel={
            <Modal.CancelButton>
              <Value name="apps.web.src.schooljaren.components.migrategroupmodal.annuleer">
                Annuleer
              </Value>
            </Modal.CancelButton>
          }
        />
      </AlertDialog.Root>
    )
  }

  // If you have multiple groups, use this
  return (
    <Dialog.Root
      open={isMigrateDialogOpen}
      onOpenChange={setIsMigrateDialogOpen}
    >
      <Dialog.Trigger asChild>
        <RoundedButton variant={"filled"} color="blue">
          <Value
            name="apps.web.src.schooljaren.components.archivemodal.alles_kopieren"
            version="1"
          >
            Kopieer alles naar huidig schooljaar
          </Value>
        </RoundedButton>
      </Dialog.Trigger>
      <SelectGroupModal
        {...props}
        allGroups={isManagerOfSchool}
        onClose={onClose}
        onSubmit={async (groupIds) => {
          await submit(groupIds)
        }}
        onRenderItem={({ group, isActive, onChange }) => (
          <GroupSelectItem
            isActive={isActive}
            group={group}
            onChange={onChange}
          />
        )}
      >
        <Stack gap="16px">
          <Modal.Title>
            <Value
              name="apps.web.src.schooljaren.components.migrategroupmodal.kopieer_alles_meerdere_groepen"
              version="1"
            >
              Kopieer alles naar de bibliotheek van huidig schooljaar
            </Value>
          </Modal.Title>
          <Hr />
        </Stack>
        <Text size={PX14}>
          <Value
            format={{ ...htmlFormatTags }}
            name="apps.web.src.schooljaren.components.migrategroupmodal.body_multiple_groups"
          >
            {`Wil je alle opgeslagen lijsten kopiëren naar de bibliotheek van het
            huidige schooljaar? Selecteer de groep(en) waarvoor je de lijsten
            wilt kopiëren. Collega's die aan dezelfde groep(en) gekoppeld zijn,
            zien deze lijsten ook in hun bibliotheek.{br}{br}<b>Let op:</b> De huidige kidslijst wordt overgeschreven 
              met de kidslijst uit het archief.`}
          </Value>
        </Text>
      </SelectGroupModal>
    </Dialog.Root>
  )
}
