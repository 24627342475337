import { useMutation, useQuery } from "@blitzjs/rpc"
import styled from "@emotion/styled"
import { Value } from "@stringtale/react"
import { Routes } from "@blitzjs/next"
import * as DropdownMenu from "@radix-ui/react-dropdown-menu"
import { FaCaretDown } from "@react-icons/all-files/fa/FaCaretDown"
import logout from "app/auth/mutations/logout"
import getOrganizatorRole from "app/auth/queries/getOrganizatorRole"
import { addQueryParams } from "app/core/utils/addQueryParams"
import Avatar from "app/files/components/Avatar"
import getFullname from "utils/users/getFullName"
import { Group } from "db"
import Link from "next/link"
import { useRouter } from "next/router"
import { LIGHT_GREY_COLOR, PX14 } from "theme/consts"
import Button from "ui/Button"
import Text from "ui/Text"
import ContextMenu from "ui/ContextMenu"
import { File } from "utils/files/types"
import useRouterQueryParam from "app/core/hooks/useRouterQueryParam"
import { useHasArchiveYears } from "app/schooljaren/hooks/useHasArchiveYears"
import { useHasSchoolyears } from "app/schooljaren/hooks/useHasSchoolyears"

type Props = {
  user: {
    id: number
    firstName: string | null
    insertion: string | null
    lastName: string | null
    avatar: File | null
    role: string
  }
  groups: Group[] | null
  group?: {
    id: number
    displayTitle: string
  }
  className?: string
  hideArchive?: boolean
}

const Root = styled(Button)`
  display: flex;
  padding: 0 28px;
  align-items: center;
  gap: 14px;
  /* border-right: ${LIGHT_GREY_COLOR} 1px solid; */
  border-left: ${LIGHT_GREY_COLOR} 1px solid;
  cursor: pointer;
`
const UserContent = styled.div`
  @media screen and (max-width: 1280px) {
    display: none;
  }
`
const UserName = styled.div`
  font-size: ${PX14};
  font-weight: 600;
  line-height: 1.5;
`
const UserGroup = styled.div`
  font-size: ${PX14};
  line-height: 1;
`

export default function HeaderUser({
  user,
  group,
  groups,
  className,
  hideArchive = false,
}: Props) {
  const [logoutMutation] = useMutation(logout)
  const [organizatorRole] = useQuery(getOrganizatorRole, {})
  const archiveYearsPresent = useHasArchiveYears()
  const hasSchoolyears = useHasSchoolyears()
  const [open, setOpen] = useRouterQueryParam("archief", "boolean")
  const router = useRouter()
  return (
    <ContextMenu
      trigger={
        <Root className={className}>
          <Avatar user={user} />
          <UserContent>
            <UserName data-hj-suppress>{getFullname(user)}</UserName>
            {group && <UserGroup>{group.displayTitle}</UserGroup>}
          </UserContent>
          <FaCaretDown />
        </Root>
      }
      items={
        <>
          <ContextMenu.Label>
            <Value name="apps.web.src.header.components.headeruser.account">
              Account
            </Value>
          </ContextMenu.Label>
          <ContextMenu.Separator />
          <ContextMenu.Item asChild>
            <a
              href="https://123zing.my.site.com/knowledgebase/s/contactsupport"
              target="_blank"
              rel="noreferrer"
            >
              <Value name="apps.web.src.header.components.headeruser.klantenservice_contact">
                Klantenservice & contact
              </Value>
            </a>
          </ContextMenu.Item>
          <ContextMenu.Item asChild>
            <a
              href="https://123zing.my.site.com/knowledgebase/s/"
              target="_blank"
              rel="noreferrer"
            >
              <Value name="apps.web.src.header.components.headeruser.hulp_en_aan_de_slag">
                Hulp en aan de slag
              </Value>
            </a>
          </ContextMenu.Item>
          {user.role !== "KID" && user.role !== "STUDENT" && (
            <ContextMenu.Item asChild>
              <Link {...Routes.SettingsPage()}>
                <Value name="apps.web.src.header.components.headeruser.profiel_instellingen">
                  Profiel & Instellingen
                </Value>
                {/*<Value name="apps.web.src.header.components.headeruser.123zing_plan">
                </Value>*/}
              </Link>
            </ContextMenu.Item>
          )}
          <ContextMenu.Separator />
          {organizatorRole && organizatorRole !== "MEMBER" ? (
            <ContextMenu.Item asChild>
              <Link {...Routes.SchoolbeheerPage()}>
                <Value name="apps.web.src.header.components.headeruser.schoolbeheer">
                  Schoolbeheer
                </Value>
              </Link>
            </ContextMenu.Item>
          ) : null}
          {organizatorRole && organizatorRole !== "MEMBER" ? (
            <>
              <ContextMenu.Item asChild>
                <Link {...Routes.SchoolbeheerPlanPage()}>
                  <Value name="apps.web.src.header.components.headeruser.123zing_plan">
                    123ZING Plan
                  </Value>
                </Link>
              </ContextMenu.Item>
            </>
          ) : null}
          {organizatorRole && organizatorRole === "MEMBER" ? (
            <ContextMenu.Item asChild>
              <Link {...Routes.MijnSchoolPage()}>
                <Value name="apps.web.src.header.components.headeruser.mijn_school">
                  Mijn school
                </Value>
              </Link>
            </ContextMenu.Item>
          ) : null}
          {groups && groups.length > 1 ? (
            <ContextMenu.Item asChild>
              <Link
                {...addQueryParams(Routes.GroupPicker(), {
                  next: router.asPath,
                })}
              >
                <Value name="apps.web.src.header.components.headeruser.wissel_groep">
                  Wissel groep
                </Value>
              </Link>
            </ContextMenu.Item>
          ) : null}
          {hasSchoolyears && archiveYearsPresent && !hideArchive ? (
            <ContextMenu.Item asChild>
              <Text onClick={() => setOpen(true)}>
                <Value name="apps.web.src.header.components.headeruser.archief">
                  Archief
                </Value>
              </Text>
            </ContextMenu.Item>
          ) : null}
          {organizatorRole ? <ContextMenu.Separator /> : null}
          <ContextMenu.Item
            onClick={async () => {
              await logoutMutation()
              sessionStorage.clear()
            }}
          >
            <Value name="apps.web.src.header.components.headeruser.uitloggen">
              Uitloggen
            </Value>
          </ContextMenu.Item>
          <DropdownMenu.Arrow
            style={{ fill: "white" }}
            width={30}
            height={15}
          />
        </>
      }
    />
  )
}
