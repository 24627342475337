import { Routes } from "@blitzjs/next"
import { invalidateQuery, useMutation, useQuery } from "@blitzjs/rpc"
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable"
import styled from "@emotion/styled"
import { FaTimes } from "@react-icons/all-files/fa/FaTimes"
import { RiHome2Fill } from "@react-icons/all-files/ri/RiHome2Fill"
import { TbEdit } from "@react-icons/all-files/tb/TbEdit"
import { TbPlus } from "@react-icons/all-files/tb/TbPlus"
import { Value } from "@stringtale/react"
import { getDisplayTitle } from "app/bulletinboard/components/BulletinBoardCard"
import BulletinBoardGrid from "app/bulletinboard/components/BulletinBoardGrid"
import batchDeleteBulletinBoard from "app/bulletinboard/mutations/batchDeleteBulletinBoard"
import deleteBulletinBoardItem from "app/bulletinboard/mutations/deleteBulletinBoardItem"
import pinBulletinBoard from "app/bulletinboard/mutations/pinBulletinBoardItem"
import updateBulletinBoardOrder from "app/bulletinboard/mutations/updateBulletinBoardOrder"
import getBulletinBoardItems, {
  GetBulletinBoardItemsResult,
} from "app/bulletinboard/queries/getBulletinBoardItems"
import { addBulletinItemModalOpenAtom } from "app/bulletinboard/state"
import useRouterQueryParam from "app/core/hooks/useRouterQueryParam"
import useToast from "app/toasts/hooks/useToast"
import { useAtom } from "jotai"
import Link from "next/link"
import { useEffect, useMemo, useState } from "react"
import { LIGHT_GREEN_COLOR } from "theme/colors"
import { PX12, PX14, PX16, PX24 } from "theme/sizes"
import Group from "ui/Group"
import Hr from "ui/Hr"
import { ScrollArea } from "ui/Modal"
import Stack from "ui/Stack"
import Text from "ui/Text"
import TextButton from "ui/TextButton"
import TextLink from "ui/TextLink"
import Title from "ui/Title"
import { multiSelectAtom } from "../state"
import AddToPlaylistModal, { AddToPlaylistLesson } from "./AddToPlaylistModal"
import { BulletinBoardCard } from "./BulletinBoardCard"
import PlaylistBatchUpdateModal from "./PlaylistBatchUpdateModal"
import { getLinkProps as getBulletinLinkProps } from "app/bulletinboard/components/BulletinBoardCard"

const Root = styled(Stack)`
  flex: 1;
  overflow: hidden;
`

const Header = styled.div`
  background: ${LIGHT_GREEN_COLOR};
  padding: 16px 32px;
`

const getSort = (index: number, length: number) => {
  return length - index
}

const Content = styled(Stack)`
  padding: 32px;
  container-type: inline-size;
`

export const BulletinBoardModalContent = () => {
  const [isAddLessonsToOtherPlaylistOpen, setIsAddLessonsToOtherPlaylistOpen] =
    useState(false)
  const [addLessonToOtherPlaylistOpen, setAddLessonToOtherPlaylistOpen] =
    useState<null | AddToPlaylistLesson>(null)
  const [_open, setOpen] = useRouterQueryParam("bibliotheek", "boolean")
  const [batchDeletePlaylistMutation] = useMutation(batchDeleteBulletinBoard)
  const [pinBulletinBoardMutation] = useMutation(pinBulletinBoard)
  const [deleteBulletinBoardItemMutation] = useMutation(deleteBulletinBoardItem)
  const [updateBulletinBoardOrderMutation] = useMutation(
    updateBulletinBoardOrder
  )
  const addToast = useToast()
  const [bulletinBoardItems, { refetch }] = useQuery(getBulletinBoardItems, {
    all: true,
  })

  const [isAddLessonOpen, setIsAddLessonOpen] = useAtom(
    addBulletinItemModalOpenAtom
  )

  const [list, setList] = useState(
    bulletinBoardItems.filter((item) => !item.isPinned)
  )
  const [pinnedList, setPinnedList] = useState(
    bulletinBoardItems.filter((item) => item.isPinned)
  )
  const [isEditMode, setIsEditMode] = useState(false)

  useEffect(() => {
    setList(bulletinBoardItems.filter((item) => !item.isPinned))
    setPinnedList(bulletinBoardItems.filter((item) => item.isPinned))
  }, [bulletinBoardItems])

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(TouchSensor)
  )

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event
    if (active && over && active.id !== over.id) {
      const item = bulletinBoardItems.find((item) => item.id === active.id)
      const moveAction =
        (isPinned: boolean) => (list: GetBulletinBoardItemsResult) => {
          const oldIndex = list.findIndex((item) => item.id === active.id)
          const newIndex = list.findIndex((item) => item.id === over.id)
          const newList = arrayMove(list, oldIndex, newIndex)
          updateBulletinBoardOrderMutation({
            isPinned,
            items: newList.map((item, index) => ({
              id: item.id,
              sort: getSort(index, newList.length),
            })),
          })
          return newList
        }
      if (item?.isPinned) {
        setPinnedList(moveAction(true))
      } else {
        setList(moveAction(false))
      }
    }
  }

  const onChangePin =
    (item: GetBulletinBoardItemsResult[number]) => async (isPinned) => {
      if (isPinned) {
        setPinnedList((l) => [...l, item])
        setList((l) => l.filter((i) => i.id !== item.id))
      } else {
        setList((l) => [...l, item])
        setPinnedList((l) => l.filter((i) => i.id !== item.id))
      }
      await pinBulletinBoardMutation({
        id: item.id,
        isPinned,
      })
      await invalidateQuery(getBulletinBoardItems)
    }

  const onDelete = (item: GetBulletinBoardItemsResult[number]) => async () => {
    await deleteBulletinBoardItemMutation({ id: item.id })
    await invalidateQuery(getBulletinBoardItems)
    addToast({
      content: (
        <Value
          name="apps.web.src.playlists.components.bulletinboardmodalcontent.is_verwijderd"
          format={{ displayTitle: getDisplayTitle(item) }}
        >
          {`{displayTitle} is verwijderd`}
        </Value>
      ),
    })
  }

  const editButton = (
    <TextButton
      size={PX14}
      onClick={() => {
        setIsEditMode((b) => !b)
        invalidateQuery(getBulletinBoardItems)
      }}
    >
      {isEditMode ? (
        <>
          <FaTimes />
          <Text size={PX14}>
            <Value
              name="apps.web.src.playlists.components.bulletinboardmodalcontent.stop_aanpassing"
              version="1"
            >
              Stop aanpassen
            </Value>
          </Text>
        </>
      ) : (
        <>
          <TbEdit strokeWidth={3} />
          <Text size={PX14}>
            <Value name="apps.web.pages.index.aanpassen">Aanpassen</Value>
          </Text>
        </>
      )}
    </TextButton>
  )

  const [multiSelect, setMultiSelect] = useAtom(multiSelectAtom)

  const selectedLessons = useMemo(() => {
    return bulletinBoardItems
      .filter(
        (item) =>
          item.type === "LESSON" && item.lesson && multiSelect.includes(item.id)
      )
      .map((item) => item.lesson) as NonNullable<
      GetBulletinBoardItemsResult[number]["lesson"]
    >[]
  }, [bulletinBoardItems, multiSelect])

  return (
    <Root>
      <Header>
        <Group align="center" gap="12px">
          <Title size={PX24}>
            <Value name="apps.web.src.playlists.components.bulletinboardmodalcontent.prikbord.title">
              Prikbord
            </Value>
          </Title>
          <TextLink bold size={PX14} as={Link} {...Routes.Home()}>
            <Group align="center" gap="4px">
              <RiHome2Fill size={14} />
              <Value name="apps.web.src.playlists.components.bulletinboardmodalcontent.bekijk_op_home">
                Bekijk op Home
              </Value>
            </Group>
          </TextLink>
        </Group>
      </Header>

      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <ScrollArea>
          <Content>
            <Stack gap="40px">
              <Stack gap="12px">
                <Group justify="space-between">
                  <Title size={PX16}>
                    <Value name="apps.web.src.playlists.components.bulletinboardmodalcontent.vastgezet_bovenaan_het_prikbord">
                      Vastgezet bovenaan het prikbord
                    </Value>
                  </Title>
                  {editButton}
                </Group>
                <Hr />
                {pinnedList.length > 0 ? (
                  <BulletinBoardGrid>
                    <SortableContext
                      items={pinnedList}
                      strategy={rectSortingStrategy}
                    >
                      {pinnedList.map((item) => (
                        <BulletinBoardCard
                          item={item}
                          key={item.id}
                          isEditMode={isEditMode}
                          onChangePin={onChangePin(item)}
                          onDelete={onDelete(item)}
                          onEdit={() => {
                            setIsEditMode(true)
                          }}
                          onAdd={() =>
                            setAddLessonToOtherPlaylistOpen(item.lesson)
                          }
                          {...getBulletinLinkProps(item)}
                        />
                      ))}
                    </SortableContext>
                  </BulletinBoardGrid>
                ) : (
                  <Text size={PX12}>
                    <Value name="apps.web.src.playlists.components.bulletinboardmodalcontent.hier_kun_je_items_op_je_prikbord_vast_zetten">
                      Zet items vast bovenaan het prikbord. Zo zie je deze items
                      altijd op dezelfde plek en kun je ze gemakkelijk
                      terugvinden.
                    </Value>
                  </Text>
                )}
              </Stack>
              <Stack gap="12px">
                <Group justify="space-between">
                  <Group gap={"12px"} align="center">
                    <Title size={PX16}>
                      <Value name="apps.web.src.playlists.components.bulletinboardmodalcontent.prikbord">
                        Prikbord
                      </Value>
                    </Title>
                    <TextButton
                      size={PX14}
                      onClick={async () => {
                        setIsAddLessonOpen(true)
                      }}
                    >
                      <Group gap={"4px"} align="center">
                        <TbPlus size={14} strokeWidth={4} />
                        <Text size={PX14}>
                          <Value name="apps.web.src.playlists.components.bulletinboardmodalcontent.toevoegen">
                            Toevoegen
                          </Value>
                        </Text>
                      </Group>
                    </TextButton>
                  </Group>
                  {editButton}
                </Group>
                <Hr />
                {list.length > 0 ? (
                  <BulletinBoardGrid>
                    <SortableContext
                      items={list}
                      strategy={rectSortingStrategy}
                    >
                      {list.map((item) => (
                        <BulletinBoardCard
                          item={item}
                          key={item.id}
                          isEditMode={isEditMode}
                          onChangePin={onChangePin(item)}
                          onDelete={onDelete(item)}
                          onEdit={() => {
                            setIsEditMode(true)
                          }}
                          onAdd={() =>
                            setAddLessonToOtherPlaylistOpen(item.lesson)
                          }
                          {...getBulletinLinkProps(item)}
                        />
                      ))}
                    </SortableContext>
                  </BulletinBoardGrid>
                ) : (
                  <Text size={PX12}>
                    <Value
                      name="apps.web.src.playlists.components.bulletinboardmodalcontent.hier_kun_je_items_op_je_prikbord_zetten"
                      format={{ br: () => <br></br> }}
                    >
                      {`Hier kun je items op je prikbord zetten.`}
                    </Value>
                  </Text>
                )}
              </Stack>
            </Stack>
          </Content>
        </ScrollArea>
      </DndContext>
      {isEditMode ? (
        <PlaylistBatchUpdateModal
          selectedLessons={selectedLessons}
          onDelete={async () => {
            await batchDeletePlaylistMutation({
              items: multiSelect,
            })
            await refetch()
            await invalidateQuery(getBulletinBoardItems)
            setMultiSelect([])
            addToast({
              content: (
                <Value name="playlists.item.verwijderen.success">
                  Items zijn verwijderd
                </Value>
              ),
            })
          }}
        />
      ) : null}

      <AddToPlaylistModal.UnControlled
        isOpen={isAddLessonsToOtherPlaylistOpen}
        setOpen={setIsAddLessonsToOtherPlaylistOpen}
        lessons={selectedLessons}
      />
      {addLessonToOtherPlaylistOpen && (
        <AddToPlaylistModal.UnControlled
          isOpen
          setOpen={() => setAddLessonToOtherPlaylistOpen(null)}
          lessons={[addLessonToOtherPlaylistOpen]}
        />
      )}
    </Root>
  )
}
