import { atom } from "jotai"
import { atomFamily } from "jotai/utils"
import toggleArray from "utils/global/toggleArray"

export const multiSelectAtom = atom([] as number[])
export const isSelectedAtom = atomFamily((id: number) => atom(
  (get) => get(multiSelectAtom).includes(id),
  (get, set, value: boolean) => {
    const current = get(multiSelectAtom).includes(id)
    if (current === value) return
    set(multiSelectAtom, toggleArray(id))
  }))