import { Value } from "@stringtale/react"
import { Routes } from "@blitzjs/next"
import { useQuery } from "@blitzjs/rpc"
import styled from "@emotion/styled"
import Link from "ui/Link"
import { ComponentProps, forwardRef } from "react"
import { DARK_BLUE_COLOR, LIGHT_GREY_COLOR, PX12, PX16 } from "theme/consts"
import Grades from "ui/Grades"
import Group from "ui/Group"
import Stack from "ui/Stack"
import LessonDescriptionPreview from "web/src/lessons/components/LessonDescriptionPreview"
import LessonListItemTitle from "web/src/lessons/components/LessonListItemTitle"
import LessonPreview, { PreviewLink } from "web/src/lessons/components/LessonPreview"
import LessonSubTitle from "web/src/lessons/components/LessonSubTitle"
import ParticipationIcons from "web/src/lessons/components/ParticipationIcons"
import getAllGrades from "web/src/grades/queries/getAllGrades"
import {
  File,
  Grade,
  HeadSubject,
  HeadSubjectGroup,
  Lesson,
  ParticipationIcon,
  SubSubject,
} from "db"
import { FileWithVariants } from "utils/files/types"
import parseStringTime from "app/rehearsal/utils/parseStringTime"
import ShowLessonButton from "./ShowLessonButton"
import { css } from "@emotion/react"

export const HIDE_IMAGE_BREAKPOINT = "650px"
export const SWAP_BUTTON_BREAKPOINT = "400px"

export type TParticipantsIcons = ParticipationIcon & {
  image: File | null
}

export type TLesson = Lesson & {
  grades: Grade[]
  // listImage: File | null
  cardImage: File | null
  previewVideo: FileWithVariants | null
  previewAudio: File | null
  radioSong: File | null
  rehearse: {
    video: FileWithVariants | null
  } | null
  subjects: (HeadSubjectGroup & {
    subjects: (HeadSubject & {
      subjects: SubSubject[]
      participationIcons: TParticipantsIcons[]
    })[]
  })[]
}

const OuterRoot = styled.div`
  container-type: inline-size;
`

const InnerRoot = styled.div`
  all: unset;
  display: grid;
  grid-template-columns: 1fr 200px;
  gap: 20px;
  text-decoration: none;
  color: ${DARK_BLUE_COLOR};
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  @container (max-width: ${SWAP_BUTTON_BREAKPOINT}) {
    display: flex;
    flex-direction: column;
  }
`

export const Root = forwardRef<HTMLDivElement, ComponentProps<typeof OuterRoot>>(({ className, ...props }, ref) => {
  return <OuterRoot className={className} ref={ref}><InnerRoot {...props} /></OuterRoot>
})

Root.displayName = "LessonListItem.Root"

export const RootWithBorder = styled(Root)`
  border-bottom: 2px solid ${LIGHT_GREY_COLOR};
  padding: 24px 55px 24px 8px;
`

export const Title = styled.h2`
  font-size: ${PX16};
  font-weight: bold;
  letter-spacing: 0.4px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
`

type ImageRootProps = {
  size?: {
    width: string
    height: string
  }
}

export const ImageRoot = styled.div<ImageRootProps>`
  position: relative;
  ${(p) =>
    p.size
      ? css`
          width: ${p.size.width};
          height: ${p.size.height};
        `
      : css`
          width: 240px;
          height: 135px;
        `};
  border-radius: 8px;
  overflow: hidden;

  @container (max-width: ${HIDE_IMAGE_BREAKPOINT}) {
    display: none;
  }
`

type Props = {
  lesson: TLesson
} & ComponentProps<typeof RootWithBorder>

const LessonListItem = ({ lesson, ...props }: Props) => {
  const [grades] = useQuery(getAllGrades, undefined)
  if (!lesson) return <></>
  return (
    <RootWithBorder {...props}>
      <Group gap="40px">
        {lesson.cardImage && (
          <PreviewLink
            {...Routes.LessonPage({
              lesson: lesson.slug,
            })}
          >
            <LessonPreview
              previewId={`listitem-${lesson.id}`}
              isPlayVisible={
                !!lesson?.previewVideo ||
                !!lesson?.previewAudio ||
                !!lesson?.rehearse?.video ||
                !!lesson?.radioSong
              }
              image={lesson.cardImage}
              previewBegin={
                lesson?.previewBegin
                  ? parseStringTime(lesson.previewBegin)
                  : undefined
              }
              previewEnd={
                lesson?.previewEnd
                  ? parseStringTime(lesson.previewEnd)
                  : undefined
              }
              file={
                lesson?.previewVideo ||
                lesson?.previewAudio ||
                lesson?.rehearse?.video ||
                lesson?.radioSong
              }
            />
          </PreviewLink>
        )}
        <Content>
          <Stack gap="8px">
            {lesson.displayTitle && (
              <Link
                {...Routes.LessonPage({
                  lesson: lesson.slug,
                })}
              >
                <LessonListItemTitle
                  title={lesson.displayTitle}
                  contentSubject={lesson.contentSubject}
                />
              </Link>
            )}
            <LessonSubTitle size={PX12} lesson={lesson} />
            <Grades
              allGrades={grades}
              activeGrades={lesson.grades}
              contentSubjectType={lesson.contentSubject}
            />
            <LessonDescriptionPreview description={lesson.searchDescription} />
          </Stack>
          <div>
            <ShowLessonButton lesson={lesson} />
          </div>
        </Content>
      </Group>
      <ParticipationIcons lesson={lesson} />
    </RootWithBorder>
  )
}

export default LessonListItem
