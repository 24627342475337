import Header from "app/header/components/Header"
import { PropsWithChildren, useEffect } from "react"
import { useCurrentUser } from "../hooks/useCurrentUser"
import Layout from "./Layout"
import { useUserGroups } from "../hooks/useUserGroups"
import HelpButton from "../components/HelpButton"
import { SUPPORT_LINKS } from "../consts"
import { useCurrentGroup } from "../hooks/useCurrentGroup"
import styled from "@emotion/styled"
import PlaylistModal from "app/playlists/components/PlaylistModal"
import { useCurrentOrganization } from "../hooks/useCurrentOrganization"
import MaintenanceBar from "../components/MaintenanceBar"
import { useQuery } from "@blitzjs/rpc"
import getMaintenance from "../queries/getMaintenance"
import ArchiveModal from "app/schooljaren/components/ArchiveModal"
import Footer from "app/footer/components/Footer"
import { useRouter } from "next/router"
import { Routes } from "@blitzjs/next"
import Stack from "ui/Stack"
import getCurrentUser from "app/users/queries/getCurrentUser"

const Root = styled.div`
  /* padding-bottom: 100px; */
`

export const StackWithBackground = styled(Stack)<{ backgroundColor?: string }>`
  background-color: ${(p) =>
    p.backgroundColor ? p.backgroundColor : undefined};
`

export default function DashboardLayout(
  props: PropsWithChildren<{
    title?: string | null
    type?: keyof typeof SUPPORT_LINKS
    backgroundColor?: string
    hideFooter?: boolean
    disablePadding?: boolean
  }>
) {
  const [currentUser, currentUserRes] = useQuery(getCurrentUser, {})
  const currentGroup = useCurrentGroup()
  const groups = useUserGroups()
  const currentOrganization = useCurrentOrganization()
  const [maintenance] = useQuery(getMaintenance, undefined)
  const router = useRouter()

  useEffect(() => {
    if (
      currentUser &&
      !currentUserRes.isLoading &&
      !currentUserRes.isFetching &&
      currentUser.organizationUsers &&
      currentUser.organizationUsers.length > 0
    ) {
      if (currentUser.organizationUsers[0].onboardingType === "ONBOARDING") {
        router.push(
          Routes.OnboardingGroup({
            next: router.query.next,
          })
        )
      } else if (
        currentUser.organizationUsers[0].onboardingType === "SCHOOLYEAR"
      ) {
        router.push(
          Routes.ReboardingWelkomPage({
            next:
              Routes.ReboardingWelkomPage().pathname === router.query.next
                ? undefined
                : router.query.next,
          })
        )
      }
    }
  }, [router, currentUser, currentUserRes])

  return (
    <Layout {...props}>
      <MaintenanceBar
        message={maintenance === "false" ? undefined : maintenance || undefined}
      />
      <Root>
        {props.type ? <HelpButton type={props.type} /> : null}
        <Header
          user={currentUser}
          group={currentGroup || undefined}
          groups={groups}
          organization={currentOrganization}
        />
        <PlaylistModal />
        <ArchiveModal />
        <StackWithBackground
          gap={props.disablePadding ? "0px" : "100px"}
          backgroundColor={props.backgroundColor}
        >
          <div>{props.children}</div>
          {!props.hideFooter && <Footer />}
        </StackWithBackground>
      </Root>
    </Layout>
  )
}
DashboardLayout.suppressFirstRenderFlicker = true
