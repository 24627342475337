import { Value } from "@stringtale/react"
import styled from "@emotion/styled"
import * as Dialog from "@radix-ui/react-dialog"
import * as RadioBase from "@radix-ui/react-radio-group"
import { FaTimes } from "@react-icons/all-files/fa/FaTimes"
import Image from "app/files/components/Image"
import NextImage from "next/image"
import {
  Dispatch,
  PropsWithChildren,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react"
import { TbMoodSmile } from "@react-icons/all-files/tb/TbMoodSmile"
import { PX14, PX32, PX40 } from "theme/sizes"
import Checkbox from "ui/Checkbox"
import Group from "ui/Group"
import Hr from "ui/Hr"
import {
  CancelButton,
  Close,
  Content,
  Footer,
  Overlay,
  Root as RootBase,
  ScrollArea,
  SubmitButton,
  Title,
} from "ui/Modal"
import Radio from "ui/Radio"
import Stack from "ui/Stack"
import Text from "ui/Text"
import getDataFromFormData from "utils/form/getDataFromFormData"
import toggleArray from "utils/global/toggleArray"
import getListTypeDisplayTitle from "../utils/getListTypeDisplayTitle"
import { TPlaylistCard } from "./PlaylistCard"
import getUserPlaylistTitle from "../utils/getUserPlaylistTitle"

const Root = styled(RootBase)`
  font-size: ${PX14};
  flex: 1;
`

const KEY = "playlists"

const Context = createContext<{
  setValue: Dispatch<SetStateAction<number[]>>
  value: number[]
} | null>(null)

const ElementWrapper = ({
  multiple,
  children,
}: PropsWithChildren<{
  multiple?: boolean
}>) => {
  const context = useContext(Context)
  if (!context) throw new Error("Context not found")
  if (multiple) {
    return <>{children}</>
  }
  return (
    <RadioBase.Root
      name={KEY}
      onValueChange={(val) => context.setValue([Number(val)])}
    >
      {children}
    </RadioBase.Root>
  )
}

type ItemProps = {
  multiple?: boolean
  playlist: TPlaylistCard
}

const getPlaylistImage = (playlist: TPlaylistCard) => {
  switch (playlist.type) {
    case "LL":
      return playlist.learningLine?.playlistImage
    case "THEME":
      return playlist.themeGroup?.globalPlaylist?.playlist?.image
  }
  return playlist.playlist?.lessons[0]?.lesson.listImage
}

const ItemRoot = styled(Group)`
  cursor: pointer;
  background: white;
  padding: 10px;
  gap: 20px;
  align-items: center;
`

const Item = ({ multiple, playlist }: ItemProps) => {
  const context = useContext(Context)
  if (!context) throw new Error("Context not found")
  const image = useMemo(() => getPlaylistImage(playlist), [playlist])
  return (
    <ItemRoot as="label">
      {multiple ? (
        <Checkbox
          checked={context.value.includes(playlist.id)}
          onCheckedChange={() => {
            context.setValue(toggleArray(playlist.id))
          }}
          value={`${playlist.id}`}
          name={`${KEY}[]`}
        />
      ) : (
        <Radio value={`${playlist.id}`} />
      )}
      {image ? (
        <Image alt="" file={image} width={48} height={48} />
      ) : (
        <NextImage
          alt=""
          src="/images/logo-square-placeholder.svg"
          width={48}
          height={48}
        />
      )}
      <Stack grow align="flex-start">
        {playlist.accessLevel === "KIDS" ? (
          <Text bold>
            <Value name="apps.web.src.playlists.components.selectplaylistmodal.kidslijst">
              Kidslijst
            </Value>
          </Text>
        ) : (
          <Text bold>{getUserPlaylistTitle(playlist)}</Text>
        )}
        <Text>{getListTypeDisplayTitle(playlist?.accessLevel, playlist?.type)}</Text>
      </Stack>
      {playlist.accessLevel === "KIDS" ? <TbMoodSmile size={PX40} /> : null}
    </ItemRoot>
  )
}

const SelectPlaylistModal = ({
  title,
  body,
  onSubmit,
  submitText,
  getIsDisabled,
  playlists,
  multiple,
}: {
  title: ReactNode
  body: ReactNode
  playlists: TPlaylistCard[]
  onSubmit: (playlistIds: number[]) => void
  submitText?: ReactNode
  getIsDisabled?: (playlist: TPlaylistCard) => boolean
  multiple?: boolean
}) => {
  const [value, setValue] = useState<number[]>([])
  return (
    <Dialog.Portal>
      <Dialog.Overlay asChild>
        <Overlay />
      </Dialog.Overlay>
      <Dialog.Content asChild>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            const formData = new FormData(e.currentTarget)
            const values = getDataFromFormData(formData)
            console.log("values", values)
            if (Array.isArray(values[KEY])) {
              onSubmit(values[KEY].map((s) => Number(s)))
            } else {
              onSubmit([parseInt(values[KEY])])
            }
          }}
        >
          <Root width="660px">
            <ScrollArea>
              <Content>
                <Stack gap="16px">
                  <Dialog.Title asChild>
                    <Title>{title}</Title>
                  </Dialog.Title>
                  {body}
                  <Context.Provider value={{ value, setValue }}>
                    <ElementWrapper multiple={multiple}>
                      <Stack>
                        <Hr />
                        {playlists?.map((playlist) => {
                          if (getIsDisabled?.(playlist)) {
                            return null
                          }
                          return (
                            <>
                              <Item multiple={multiple} playlist={playlist} />
                              <Hr />
                            </>
                          )
                        })}
                      </Stack>
                    </ElementWrapper>
                  </Context.Provider>
                </Stack>
              </Content>
            </ScrollArea>
            <Footer>
              <Dialog.Close asChild>
                <CancelButton type="button">
                  <Value name="apps.web.src.playlists.components.selectplaylistmodal.annuleren">
                    Annuleren
                  </Value>
                </CancelButton>
              </Dialog.Close>
              <SubmitButton type="submit" disabled={!value.length}>
                {submitText || "Toevoegen"}
              </SubmitButton>
            </Footer>
            <Dialog.Close asChild>
              <Close>
                <FaTimes />
              </Close>
            </Dialog.Close>
          </Root>
        </form>
      </Dialog.Content>
    </Dialog.Portal>
  )
}

export default SelectPlaylistModal
