import { invalidateQuery, useMutation } from "@blitzjs/rpc"
import styled from "@emotion/styled"
import { Value } from "@stringtale/react"
import ContentSubjectIcon from "app/content-subjects/components/ContentSubjectIcon"
import getContentSubjectColor from "app/content-subjects/getContentSubjectColor"
import Image from "app/files/components/Image"
import { GetAllGradesResult } from "app/grades/queries/getAllGrades"
import { BulletinBoardItemType } from "db"
import { useMemo } from "react"
import { DARKER_GREY_COLOR, PX12 } from "theme/consts"
import ButtonGroup from "ui/ButtonGroup"
import Group from "ui/Group"
import RoundedButtonBase from "ui/RoundedButton"
import Stack from "ui/Stack"
import Text from "ui/Text"
import addBulletinBoardItem from "../mutations/addBulletinBoardItem"
import { GetBulletinAddThemeItemsResult } from "../queries/getBulletinAddThemeItems"
import getBulletinBoardItems, {
  GetBulletinBoardItemsResult,
} from "../queries/getBulletinBoardItems"
import BulletinAddListItem from "./BulletinAddListItem"
import Loader from "ui/Loader"
import { EmptyContainer, EmptyImage } from "./BulletinAddItemPlaceholder"
import { GetBulletinThemeGroupsResult } from "../queries/getBulletinThemeGroups"
import { GetCategoryThemesResult } from "../queries/getCategoryThemes"

const RoundedButton = styled(RoundedButtonBase)`
  font-size: ${PX12};
`

const SubjectIconContainer = styled.div<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ color }) => color};
  border-radius: 100%;
  width: 20px;
  height: 20px;

  svg {
    width: 14px;
    height: 14px;
  }
`

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(2, minmax(0, 1fr));
  border-radius: 8px;
  overflow: hidden;
  img {
    object-fit: cover;
  }
  width: 100%;
  height: 100%;
`

const BulletinAddThemeItem = ({
  themeGroup,
  selectedTheme,
  bulletinBoardItems,
}: {
  themeGroup: NonNullable<GetBulletinThemeGroupsResult>[number]
  selectedTheme?: NonNullable<GetCategoryThemesResult>[number]
  bulletinBoardItems: GetBulletinBoardItemsResult
}) => {
  const [addBulletinBoardItemMutation, addBulletinBoardItemRes] =
    useMutation(addBulletinBoardItem)

  const activeGrades = useMemo(() => {
    const grades: GetAllGradesResult = []
    themeGroup.globalPlaylist?.playlist.lessons.map((lesson) =>
      lesson.lesson.grades.map((grade) =>
        grades.includes(grade) ? null : grades.push(grade)
      )
    )
    return grades
  }, [themeGroup])

  const hasTheme = !!bulletinBoardItems.find(
    (item) =>
      item.type === BulletinBoardItemType.THEME_GROUP &&
      item.themeGroupId === themeGroup.id
  )

  const playlist = themeGroup.globalPlaylist?.playlist

  return (
    <div>
      <BulletinAddListItem
        heading={
          <Value name="apps.web.src.bulletinboard.components.bulletinaddthemeitem.thema">
            Thema
          </Value>
        }
        title={themeGroup.displayTitle}
        activeGrades={activeGrades}
        image={
          <>
            {playlist?.lessons ? (
              <ImageGrid>
                {playlist?.lessons.map(
                  (lesson) =>
                    lesson.lesson.cardImage && (
                      <Image
                        alt=""
                        file={lesson.lesson.cardImage}
                        key={lesson.lesson.id}
                        width={115}
                        height={65}
                      />
                    )
                )}
                {playlist?.lessons &&
                  [...Array(4 - playlist?.lessons.length)].map(function () {
                    return (
                      <EmptyContainer>
                        <EmptyImage />
                      </EmptyContainer>
                    )
                  })}
              </ImageGrid>
            ) : null}
          </>
        }
        right={
          <>
            <Stack justify="space-between">
              <Stack gap="8px">
                <Text bold size={PX12}>
                  <Value name="apps.web.src.bulletinboard.components.bulletinaddthemeitem.methode">
                    Methode
                  </Value>
                </Text>
                <Stack gap="4px">
                  {selectedTheme?.contentSubjects.map((subject) => {
                    return (
                      <Group
                        gap="8px"
                        align="center"
                        justify="flex-start"
                        key={subject.id}
                      >
                        <SubjectIconContainer
                          color={getContentSubjectColor(subject.type)}
                        >
                          <ContentSubjectIcon type={subject.type} />
                        </SubjectIconContainer>
                        <Text size={PX12}>{subject.displayTitle}</Text>
                      </Group>
                    )
                  })}
                </Stack>
              </Stack>
              <ButtonGroup>
                <RoundedButton
                  size="small"
                  variant="filled"
                  style={
                    hasTheme
                      ? { opacity: 1, backgroundColor: DARKER_GREY_COLOR }
                      : {}
                  }
                  disabled={hasTheme}
                  onClick={async () => {
                    await addBulletinBoardItemMutation({
                      type: BulletinBoardItemType.THEME_GROUP,
                      themeGroupId: themeGroup.id,
                    })
                    await invalidateQuery(getBulletinBoardItems)
                  }}
                >
                  {hasTheme ? (
                    <Value name="app.bulletinboard.components.bulletinaddthemeitem.thema_al_op_prikbord">
                      Thema al op prikbord
                    </Value>
                  ) : addBulletinBoardItemRes.isLoading ? (
                    <Loader color="#FFFFFF" />
                  ) : (
                    <Value name="app.bulletinboard.components.bulletinaddthemeitem.zet_op_prikbord">
                      Zet op prikbord
                    </Value>
                  )}
                </RoundedButton>
              </ButtonGroup>
            </Stack>
          </>
        }
      />
    </div>
  )
}

export default BulletinAddThemeItem
