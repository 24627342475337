import { UserListAccessLevel } from "db"


export default function getListTypeCanAdd(accessLevel: UserListAccessLevel) {
  switch (accessLevel) {
    case "PERSONAL":
    case "GROUP":
    case "KIDS":
      return true
    case "SCHOOL":
    case "GLOBAL":
      return false
  }
}