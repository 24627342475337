import { Value } from "@stringtale/react"
import Radio from "ui/Radio"
import Stack from "ui/Stack"
import Text from "ui/Text"
import { Form } from "utils/form/createForm"
import { ZodObject, z } from "zod"
import { ACCESS_LEVELS } from "../consts"
import { useQuery } from "@blitzjs/rpc"
import getCurrentGroup from "app/users/queries/getCurrentGroup"
import TextLink from "ui/TextLink"
import { useState } from "react"
import { PX14 } from "theme/sizes"
import { AddUserListInput } from "../validations"


export default function PlaylistAccessLevels<T extends ZodObject<any>>({
  form,
  isOpen,
  setIsOpen,
  accessLevel,
  onValueChange
}: {
  form: Form<T>
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  accessLevel: string
  onValueChange: (accessLevel: string) => void
}) {
  const [group] = useQuery(getCurrentGroup, undefined)
  if (!group) return null
  if (isOpen) {
    return (
      <form.Field name="accessLevel">
        <Stack gap="12px">
          <Stack gap="4px">
            <Text bold size={PX14}>
              <Value name="apps.web.src.playlists.components.playlistaccesslevels.title" version="1">
                Zichtbaarheid
              </Value>
            </Text>
            <Text size={PX14}>
              <Value name="apps.web.src.playlists.components.playlistaccesslevels.body">
                Je kunt hiermee de zichtbaarheid van een groepslijst instellen.
                Maak je een lijst privé? Dan kun je die niet meer terug zetten
                naar lijst die zichtbaar is voor een groep.
              </Value>
            </Text>
          </Stack>
          <form.Control>
            <Radio.Group value={accessLevel} onValueChange={onValueChange}>
              <Radio
                label={
                  <Value
                    name="apps.web.src.playlists.components.playlistaccesslevels.zichtbaar_voor_iedereen_van_group_displaytitle"
                    format={{ groupName: group?.displayTitle }}
                  >
                    {`Zichtbaar voor iedereen van {groupName}`}
                  </Value>
                }
                value="CURRENT_GROUP"
              />
              <Radio
                label={
                  <Value name="apps.web.src.playlists.components.playlistaccesslevels.zichtbaar_voor_geselecteerde_groepen">
                    Zichtbaar voor geselecteerde groepen
                  </Value>
                }
                value="SELECTED_GROUP"
                disabled={!group}
              />
              <Radio
                label={
                  <Value name="apps.web.src.playlists.components.playlistaccesslevels.zichtbaar_alleen_voor_mij_priv">
                    Zichtbaar alleen voor mij privé
                  </Value>
                }
                value="PERSONAL"
              />
            </Radio.Group>
          </form.Control>
          <form.Message />
        </Stack>
      </form.Field>
    )
  }
  return (
    <Stack gap="8px">
      <TextLink inline onClick={() => setIsOpen(true)}>
        <Value
          name="apps.web.src.playlists.components.playlistaccesslevels.zichtbaar_voor"
          format={{ group: group?.displayTitle }}
        >
          {`Zichtbaar voor iedereen van {group}`}
        </Value>
      </TextLink>
    </Stack>
  )
}
