import { Dispatch, SetStateAction, useState } from "react"
import ContentSubjectFilter from "../../content-subjects/components/ContentSubjectFilter"
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerIconClose,
  DrawerOverlay,
  DrawerPortal,
  DrawerTrigger,
} from "../../core/components/Drawer"
import GroupFilter from "../../groups/components/GroupFilter"
import { useCurrentOrganization } from "../../core/hooks/useCurrentOrganization"
import { OpenTabState } from "app/studio/components/StudioModal"
import Group from "ui/Group"
import { FaSlidersH } from "@react-icons/all-files/fa/FaSlidersH"
import { Value } from "@stringtale/react"
import { FaTimes } from "@react-icons/all-files/fa/FaTimes"
import Stack from "ui/Stack"
import Text from "ui/Text"
import { Separator } from "ui/Separator"
import styled from "@emotion/styled"
import RoundedButton from "ui/RoundedButton"
import hasOnlyOneLearningLine from "app/subscriptions/hasOnlyOneLearningLine"
import { ContentSubjectType, Grade } from "db"
import Title from "ui/Title"
import { PX16 } from "theme/sizes"
import * as Grades from "app/schoolbeheer/components/groups/Grades"
import toggleArray from "utils/global/toggleArray"

const FilterButton = styled(RoundedButton)`
  border-radius: 8px;
  height: 46px;
  /* padding-left: 11px; */
`

export default function FilterButtons({
  grades,
  currentGrades,
  setGrades,
  vakken,
  setVakken,
}: {
  grades: Grade[]
  currentGrades: number[]
  setGrades: Dispatch<SetStateAction<number[]>>
  vakken: ContentSubjectType[]
  setVakken: Dispatch<SetStateAction<ContentSubjectType[]>>
}) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const currentSchool = useCurrentOrganization()
  const [openFilterDrawer, setOpenFilterDrawer] = useState<OpenTabState | null>(
    null
  )

  const gradeFilter = (
    <>
      <Title size={PX16}>
        <Value name="apps.web.src.search.components.searchfilters.groep">
          Groep
        </Value>
      </Title>
      <Group wrap gap="6px">
        {grades.map((grade) => (
          <Grades.Root
            checked={currentGrades.includes(grade.id)}
            onCheckedChange={() => setGrades(toggleArray(grade.id))}
          >
            {grade.number}
          </Grades.Root>
        ))}
      </Group>
    </>
  )

  return (
    <Group gap="6px">
      <FilterButton
        onClick={() => setIsDrawerOpen(true)}
        variant="filled"
        left={<FaSlidersH />}
      >
        <Value name="apps.web.src.studio.components.studiothemegroup.filter">
          Filter
        </Value>
      </FilterButton>
      <Drawer open={isDrawerOpen} onOpenChange={setIsDrawerOpen}>
        <DrawerPortal>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader>
              <DrawerIconClose>
                <FaTimes size={20} />
              </DrawerIconClose>
            </DrawerHeader>
            <DrawerDescription>
              <Stack gap="12px">
                <Text bold>
                  <Value name="apps.web.src.studio.components.studiothemegroup.filter">
                    Filter
                  </Value>
                </Text>
                <Separator />
                {!hasOnlyOneLearningLine(currentSchool?.subscription) && (
                  <>
                    <ContentSubjectFilter {...{ vakken, setVakken }} />
                    <Separator />
                  </>
                )}
                <Stack gap="16px">{gradeFilter}</Stack>

                <Separator />
              </Stack>
            </DrawerDescription>
            <DrawerFooter>
              <DrawerClose asChild>
                <RoundedButton variant="filled">
                  <Value name="apps.web.src.studio.components.studiothemegroup.filter_resultaten">
                    Filter resultaten
                  </Value>
                </RoundedButton>
              </DrawerClose>
            </DrawerFooter>
          </DrawerContent>
        </DrawerPortal>
      </Drawer>

      {!hasOnlyOneLearningLine(currentSchool?.subscription) && (
        <Drawer>
          <DrawerTrigger asChild>
            <FilterButton
              onClick={() => setOpenFilterDrawer("new")}
              color="blue"
            >
              <Value name="apps.web.src.studio.components.studiothemegroup.methode">
                Methode
              </Value>
            </FilterButton>
          </DrawerTrigger>
          <DrawerPortal>
            <DrawerOverlay />
            <DrawerContent>
              <DrawerHeader>
                <DrawerIconClose>
                  <FaTimes size={20} />
                </DrawerIconClose>
              </DrawerHeader>
              <DrawerDescription>
                <Stack gap="12px">
                  <ContentSubjectFilter {...{ vakken, setVakken }} />
                </Stack>
              </DrawerDescription>
              <DrawerFooter>
                <DrawerClose asChild>
                  <RoundedButton variant="filled">
                    <Value name="apps.web.src.studio.components.studiothemegroup.filter_resultaten">
                      Filter resultaten
                    </Value>
                  </RoundedButton>
                </DrawerClose>
              </DrawerFooter>
            </DrawerContent>
          </DrawerPortal>
        </Drawer>
      )}

      <Drawer>
        <DrawerTrigger asChild>
          <FilterButton onClick={() => setOpenFilterDrawer("new")} color="blue">
            <Value name="apps.web.src.studio.components.studiothemegroup.groepen">
              Groepen
            </Value>
          </FilterButton>
        </DrawerTrigger>
        <DrawerPortal>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader>
              <DrawerIconClose>
                <FaTimes size={20} />
              </DrawerIconClose>
            </DrawerHeader>
            <DrawerDescription>
              <Stack gap="12px">{gradeFilter}</Stack>
            </DrawerDescription>
            <DrawerFooter>
              <DrawerClose asChild>
                <RoundedButton variant="filled">
                  <Value name="apps.web.src.studio.components.studiothemegroup.filter_resultaten">
                    Filter resultaten
                  </Value>
                </RoundedButton>
              </DrawerClose>
            </DrawerFooter>
          </DrawerContent>
        </DrawerPortal>
      </Drawer>
    </Group>
  )
}
