import styled from "@emotion/styled"
import Group from "ui/Group"
import AddToPlaylistModal, {
  AddToPlaylistProps,
} from "app/playlists/components/AddToPlaylistModal"
import { useCurrentUser } from "app/core/hooks/useCurrentUser"
import { TbPlus } from "@react-icons/all-files/tb/TbPlus"
import RoundedButtonBase from "ui/RoundedButton"
import { PX12 } from "theme/sizes"
import Link from "ui/Link"
import { Routes } from "@blitzjs/next"
import { Value } from "@stringtale/react"
import { GetHasSubmittableLesson } from "../utils/getHasSubmittable"
import ButtonGroup from "ui/ButtonGroup"

// const GroupedButton = styled(Group)`
//   display: inline-flex;
//   & > * {
//     border-radius: 0px !important;
//   }
//   border-radius: 25px;
//   overflow: hidden;
// `

const RoundedButton = styled(RoundedButtonBase)`
  font-size: ${PX12};
`

type Props = {
  lesson: GetHasSubmittableLesson & {
    id: number
    displayTitle: string | null
    slug: string
  }
}

const LikeButton = styled(RoundedButtonBase)`
  font-size: ${PX12};
  padding: 0 13px;
`

function LikeLessonButton({
  lesson,
  ...props
}: Props) {
  const user = useCurrentUser()

  if (!user) return null

  return (
    <AddToPlaylistModal.Controlled
      lessons={[lesson]}
      trigger={
        <LikeButton variant="filled" size="small">
          <TbPlus color="white" size={20} />
        </LikeButton>
      }
      {...props}
    />
  )
}

export default function ShowLessonButton({ lesson }: Props) {
  return (
    <ButtonGroup>
      <RoundedButton
        size="small"
        variant="filled"
        as={Link}
        {...Routes.LessonPage({
          lesson: lesson.slug,
        })}
      >
        <Value name="apps.web.src.lessons.components.lessonlistitem.bekijk_les">
          Bekijk les
        </Value>
      </RoundedButton>
      <LikeLessonButton lesson={lesson} />
    </ButtonGroup>
  )
}
