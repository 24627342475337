import { invalidateQuery, useMutation } from "@blitzjs/rpc"
import styled from "@emotion/styled"
import { Value } from "@stringtale/react"
import Avatar from "app/files/components/Avatar"
import Image from "app/files/components/Image"
import { GetAllGradesResult } from "app/grades/queries/getAllGrades"
import {
  ImageRoot,
  Root as RootBase,
} from "app/lessons/components/LessonListItem"
import { BulletinBoardItemType } from "db"
import { useMemo } from "react"
import { DARKER_GREY_COLOR, GREEN_COLOR, PX12 } from "theme/consts"
import ButtonGroup from "ui/ButtonGroup"
import Group from "ui/Group"
import RoundedButtonBase from "ui/RoundedButton"
import Stack from "ui/Stack"
import Text from "ui/Text"
import addBulletinBoardItem from "../mutations/addBulletinBoardItem"
import { GetBulletinAddGroupListItemsResult } from "../queries/getBulletinAddGroupListItems"
import getBulletinBoardItems, {
  GetBulletinBoardItemsResult,
} from "../queries/getBulletinBoardItems"
import BulletinAddListItem from "./BulletinAddListItem"
import Loader from "ui/Loader"
import { EmptyContainer, EmptyImage } from "./BulletinAddItemPlaceholder"

const RoundedButton = styled(RoundedButtonBase)`
  font-size: ${PX12};
`

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(2, minmax(0, 1fr));
  border-radius: 8px;
  overflow: hidden;
  img {
    object-fit: cover;
  }
  width: 100%;
  height: 100%;
`

const BulletinAddGroupListItem = ({
  groupPlaylist,
  bulletinBoardItems,
}: {
  groupPlaylist: GetBulletinAddGroupListItemsResult[number]
  bulletinBoardItems: GetBulletinBoardItemsResult
}) => {
  const [addBulletinBoardItemMutation, addBulletinBoardItemRes] =
    useMutation(addBulletinBoardItem)

  const activeGrades = useMemo(() => {
    const grades: GetAllGradesResult = []
    groupPlaylist.playlist?.lessons.map((lesson) =>
      lesson.lesson.grades.map((grade) =>
        grades.includes(grade) ? null : grades.push(grade)
      )
    )
    return grades
  }, [groupPlaylist])

  const hasList = !!bulletinBoardItems.find(
    (item) =>
      (item.type === BulletinBoardItemType.GROUP_LIST ||
        item.type === BulletinBoardItemType.KIDS_LIST) &&
      item.playlistId === groupPlaylist.id
  )

  return (
    <div>
      <BulletinAddListItem
        heading={
          groupPlaylist.accessLevel === "GROUP" ? (
            <Value name="apps.web.src.bulletinboard.components.bulletinaddgrouplistitem.groepslijst">
              Groepslijst
            </Value>
          ) : groupPlaylist.accessLevel === "KIDS" ? (
            <Value name="apps.web.src.bulletinboard.components.bulletinaddgrouplistitem.kidslijst">
              Kidslijst
            </Value>
          ) : (
            <Value name="apps.web.src.bulletinboard.components.bulletinaddgrouplistitem.lijst">
              Lijst
            </Value>
          )
        }
        title={groupPlaylist.playlist?.displayTitle}
        // description={groupPlaylist.description}
        activeGrades={activeGrades}
        image={
          <>
            {groupPlaylist.playlist?.lessons ? (
              <ImageGrid>
                {groupPlaylist.playlist?.lessons.map(
                  (lesson) =>
                    lesson.lesson.cardImage && (
                      <Image
                        alt=""
                        file={lesson.lesson.cardImage}
                        key={lesson.lesson.id}
                        width={115}
                        height={65}
                      />
                    )
                )}
                {groupPlaylist.playlist?.lessons &&
                  [...Array(4 - groupPlaylist.playlist?.lessons.length)].map(
                    function () {
                      return (
                        <EmptyContainer>
                          <EmptyImage />
                        </EmptyContainer>
                      )
                    }
                  )}
              </ImageGrid>
            ) : null}
          </>
        }
        right={
          <>
            <Stack justify="space-between">
              <Stack gap="8px">
                {groupPlaylist.owner && (
                  <>
                    <Text bold size={PX12}>
                      <Value name="apps.web.src.bulletinboard.components.bulletinaddgrouplistitem.gemaakt_door">
                        Gemaakt door
                      </Value>
                    </Text>
                    <Stack gap="4px">
                      <Group gap="8px" align="center" justify="flex-start">
                        <Avatar
                          user={groupPlaylist.owner}
                          width={20}
                          height={20}
                        />
                        <Text size={PX12}>{groupPlaylist.owner.firstName}</Text>
                      </Group>
                    </Stack>
                  </>
                )}
              </Stack>
              <ButtonGroup>
                <RoundedButton
                  size="small"
                  variant="filled"
                  style={
                    hasList
                      ? { opacity: 1, backgroundColor: DARKER_GREY_COLOR }
                      : {}
                  }
                  disabled={hasList}
                  onClick={async () => {
                    await addBulletinBoardItemMutation({
                      playlistId: groupPlaylist.id,
                      type:
                        groupPlaylist.accessLevel === "KIDS"
                          ? BulletinBoardItemType.KIDS_LIST
                          : BulletinBoardItemType.GROUP_LIST,
                    })
                    await invalidateQuery(getBulletinBoardItems)
                  }}
                >
                  {hasList ? (
                    <Value name="app.bulletinboard.components.bulletinaddgrouplistitem.zet_op_prikbord">
                      Lijst al op prikbord
                    </Value>
                  ) : addBulletinBoardItemRes.isLoading ? (
                    <Loader color="#FFFFFF" />
                  ) : (
                    <Value name="app.bulletinboard.components.bulletinaddgrouplistitem.zet_op_prikbord">
                      Zet op prikbord
                    </Value>
                  )}
                </RoundedButton>
              </ButtonGroup>
            </Stack>
          </>
        }
      />
    </div>
  )
}

export default BulletinAddGroupListItem
