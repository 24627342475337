import { ZodFirstPartySchemaTypes, z } from "zod"
import { SORT_OPTIONS } from "./consts"

const zodTransformNull = (p: ZodFirstPartySchemaTypes) => {
  return p.optional().nullable().transform(val => val || undefined)
}

export const AddUserListInput = z.object({
  displayTitle: z.string().min(1, "Naam is verplicht"),
  description: z.string().optional(),
  color: z.string(),
  accessLevel: z.enum(["PERSONAL", "CURRENT_GROUP", "SELECTED_GROUP"]),
  selectedGroups: z.array(z.number()).optional(),
})

export const UpdateUserListInput = z.object({
  id: z.number(),
  displayTitle: z.string().optional(),
  color: z.string().optional(),
  description: z.string().optional(),
  sortItemsBy: z.enum(SORT_OPTIONS).optional()
})

export const DuplicateUserPlaylistInput = z.object({
  id: z.number(),
  displayTitle: z.string().min(1, "Naam is verplicht"),
  description: zodTransformNull(z.string()),
  color: zodTransformNull(z.string()),
})

export const UpdateUserListOrderInput = z.object({
  id: z.number(),
  items: z.array(z.object({
    id: z.number(),
    sort: z.number()
  })),
})

export const BatchDeleteUserListInput = z.object({
  id: z.number(),
  items: z.array(z.number()),
})
