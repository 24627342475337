import styled from "@emotion/styled"
import * as Dialog from "@radix-ui/react-dialog"
import * as Form from "@radix-ui/react-form"
import {
  ALERT_MODAL_OVERLAY_Z_INDEX,
  ALERT_MODAL_Z_INDEX,
  DARK_BLUE_COLOR,
  MODAL_OVERLAY_Z_INDEX,
  MODAL_Z_INDEX,
  ORANGE_COLOR,
  PX14,
  PX24,
} from "theme/consts"
import { poppins } from "theme/fonts"
import { MODAL_BOX_SHADOW } from "theme/styles"
import Button from "ui/Button"
import { FaTimes } from "@react-icons/all-files/fa/FaTimes"
import TitleBase from "ui/Title"
import RoundedButton from "./RoundedButton"

export const RADIUS = "16px"

export const Overlay = styled.div<{ isAlert?: boolean }>`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  inset: 0;
  z-index: ${(p) =>
    p.isAlert ? ALERT_MODAL_OVERLAY_Z_INDEX : MODAL_OVERLAY_Z_INDEX};
`

export const Root = styled.div<{ isAlert?: boolean; width?: string }>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: ${(p) => (p.isAlert ? ALERT_MODAL_Z_INDEX : MODAL_Z_INDEX)};
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
  max-width: ${(p) => p.width || "660px"};
  max-height: calc(100vh - 40px);
  box-sizing: border-box;
  overflow: hidden;
  background-color: white;
  border-radius: ${RADIUS};
  font-family: ${poppins.style.fontFamily};
  ${MODAL_BOX_SHADOW}
`

export const Content = styled.div`
  padding: 40px;
  width: 100%;
  box-sizing: border-box;
  flex: 1;
  display: flex;
  overflow-y: hidden;
  overflow-y: auto;
`

export const Title = styled(TitleBase)`
  font-size: ${PX24};
  font-weight: 900;
`

export const SubmitButton = styled(RoundedButton)`
  padding: 14px 24px;
  background-color: ${ORANGE_COLOR};
  border-radius: 40px;
  border: none;
  color: white;
  font-size: ${PX14};
  font-weight: 600;
  &:disabled {
    opacity: 0.5;
  }
`
SubmitButton.defaultProps = {
  type: "submit",
}

export const CancelButton = styled(RoundedButton)`
  padding: 14px 24px;
  background-color: white;
  /* border: none; */
  color: ${ORANGE_COLOR};
  font-size: ${PX14};
  font-weight: 600;
`
CancelButton.defaultProps = {
  variant: "bordered",
  type: "button",
}

export const Close = styled(Button)`
  position: absolute;
  top: 8px;
  right: 8px;
  color: ${DARK_BLUE_COLOR};
  /* border: 2px solid #ffffff; */
  border-radius: 36px;
  width: 34px;
  height: 34px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`

Close.defaultProps = {
  children: <FaTimes color={DARK_BLUE_COLOR} size="20px" />,
}

export const Buttons = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`

export const Footer = styled(Buttons)`
  padding: 40px;
`

export const ScrollArea = styled.div`
  overflow-y: auto;
  flex: 1;
`
