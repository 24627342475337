import { useQuery } from "@blitzjs/rpc"
import styled from "@emotion/styled"
import * as Accordion from "@radix-ui/react-accordion"
import * as Dialog from "@radix-ui/react-dialog"
import * as Select from "@radix-ui/react-select"
import { FaTimes } from "@react-icons/all-files/fa/FaTimes"
import { TbCaretRightFilled } from "@react-icons/all-files/tb/TbCaretRightFilled"
import { TbChevronRight } from "@react-icons/all-files/tb/TbChevronRight"
import { isInsideToolbar } from "@stringtale/react"
import { Value } from "@stringtale/react/dist"
import useRouterQueryParam from "app/core/hooks/useRouterQueryParam"
import getAllGrades from "app/grades/queries/getAllGrades"
import PlaylistMenuCircleBase from "app/playlists/components/PlaylistMenuCircle"
import {
  MODAL_QUERY_KIDS_TYPE_VALUE,
  MODAL_QUERY_SHARE_TYPE_VALUE,
  RADIUS,
} from "app/playlists/consts"
import getUserPlaylistColor from "app/playlists/utils/getUserPlaylistColor"
import getUserPlaylistTitle from "app/playlists/utils/getUserPlaylistTitle"
import { AnimatePresence, motion } from "framer-motion"
import { Suspense, useEffect, useMemo, useState } from "react"
import {
  BORDER_GREY_COLOR,
  DARK_BLUE_COLOR,
  PLAYLIST_MODAL_OVERLAY_Z_INDEX,
  PLAYLIST_MODAL_Z_INDEX,
  PX20,
} from "theme/consts"
import { poppins } from "theme/fonts"
import { MODAL_BOX_SHADOW } from "theme/styles"
import Group from "ui/Group"
import * as Modal from "ui/Modal"
import {
  AccordionChevron,
  Footer,
  Header,
  Item as ItemBase,
  Root as RootBase,
  SubItem as SubItemBase,
  SubItems as SubItemsBase,
} from "ui/ModalSideBar"
import RoundedButtonBase from "ui/RoundedButton"
import SelectInput, {
  SelectLabel,
  SelectSeparator,
  StyledItem,
  StyledItemIndicator,
} from "ui/SelectInput"
import Stack from "ui/Stack"
import getArchiveGroupPlaylists from "../queries/getArchiveGroupPlaylists"
import getArchiveLists, {
  GetArchiveListsResult,
} from "../queries/getArchiveLists"
import { ArchiveModalContent } from "./ArchiveModalContent"
import MigrateGroupModal from "./MigrateGroupModal"
import { keyframes } from "@emotion/react"

const Overlay = styled(Dialog.Overlay)`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  inset: 0;
  z-index: ${PLAYLIST_MODAL_OVERLAY_Z_INDEX};

  animation: ${keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
    `} 150ms ease-out;
` //.withComponent(motion.div)

const Root = styled.div`
  /* width: calc(100vw - 20px); */
  /* height: calc(100vh - 20px); */
  position: fixed;
  top: 25px;
  left: 25px;
  bottom: 25px;
  right: 25px;
  /* transform: translate(-50%, -50%); */
  z-index: ${PLAYLIST_MODAL_Z_INDEX};

  background-color: white;
  border-radius: ${RADIUS};
  font-family: ${poppins.style.fontFamily};
  /* overflow: hidden; */
  display: flex;
  ${MODAL_BOX_SHADOW}

  animation: ${keyframes`
    0% {
      opacity: 0;
      transform: translateY(-1000px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
    `} 150ms ease-out;
`

const Right = styled.div`
  background-color: white;
  flex: 1;
  border-bottom-right-radius: ${RADIUS};
  border-top-right-radius: ${RADIUS};
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const ModalClose = styled(Modal.Close)`
  border: 2px solid #ffffff;
  background-color: ${DARK_BLUE_COLOR};
  color: white;
`

const SideBarRoot = styled(RootBase)`
  background-color: ${BORDER_GREY_COLOR};
  color: ${DARK_BLUE_COLOR};
`

const Separator = styled.div`
  height: 1px;
  width: 100%;
  background-color: #b2b1c9;
`

const BookmarkTitle = styled.div`
  font-weight: bold;
  font-size: ${PX20};
  display: flex;
  align-items: center;
`

const RoundedButton = styled(RoundedButtonBase)`
  width: 100%;
`

const Item = styled(ItemBase)`
  background-color: ${BORDER_GREY_COLOR};
  color: ${DARK_BLUE_COLOR};
  border-bottom: #b2b1c9 1px solid;
`

const SubItems = styled(SubItemsBase)`
  background-color: ${BORDER_GREY_COLOR};
  color: ${DARK_BLUE_COLOR};
  border-bottom: #b2b1c9 1px solid;
`

const SubItem = styled(SubItemBase)`
  color: ${DARK_BLUE_COLOR};
`

const PlaylistMenuCircle = styled(PlaylistMenuCircleBase)`
  color: white;
`

const ScrollArea = styled.div`
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
`

const InnerArchiveModal = () => {
  const [grades] = useQuery(getAllGrades, undefined)
  const [archiveLists] = useQuery(getArchiveLists, undefined)
  const [selectedGroup, setSelectedGroup] = useState<
    NonNullable<GetArchiveListsResult>[0]["groups"][0] | null
  >(null)
  const [isAddLessonOpen, setIsAddLessonOpen] = useState(false)
  const [queryParam, setOpen] = useRouterQueryParam("archief", "string")
  const open = queryParam !== undefined

  const [yearValue, setYearValue] = useState<string>("")
  const [groupValue, setGroupValue] = useState<string>("")
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState<boolean>(false)

  const [yearSelectOpen, setYearSelectOpen] = useState<boolean>(false)
  const [groupSelectOpen, setGroupSelectOpen] = useState<boolean>(false)

  const [selectedYearGroupList, setSelectedYearGroupList] = useState<
    NonNullable<GetArchiveListsResult>[0]["groups"]
  >([])

  const [archiveGroupPlaylists] = useQuery(getArchiveGroupPlaylists, {
    groupId: selectedGroup?.id,
  })

  useEffect(() => {
    const selectedYear = archiveLists?.find(
      (el) => el.id.toString() === yearValue
    )
    if (selectedYear) {
      // if (selectedYear.groups.length > 0) {
      setSelectedYearGroupList(selectedYear.groups)
      // }
    }
  }, [archiveLists, yearValue, setGroupValue])

  /* Updating selectedGroup state when yearValue and groupValue select field values are updated */
  useEffect(() => {
    const selectedYear = archiveLists?.find(
      (el) => el.id.toString() === yearValue
    )
    if (selectedYear && selectedYear.groups.length > 0) {
      const newSelectedGroup = selectedYear.groups.find(
        (el) => el.id.toString() === groupValue
      )
      if (newSelectedGroup) {
        setSelectedGroup(newSelectedGroup)
      }
    }
  }, [archiveLists, yearValue, groupValue, setSelectedGroup])

  /* Assigning default value for year and group if the select fields are empty */
  useEffect(() => {
    if (!archiveGroupPlaylists || !archiveLists || archiveLists.length === 0)
      return
    if (!yearValue) {
      // console.log("assign default year...")
      setYearValue(archiveLists[0].id.toString())
    }
    if (!!yearValue && !groupValue && archiveLists[0].groups.length > 0) {
      // console.log("assign default group...")
      setGroupValue(archiveLists[0].groups[0].id.toString())
    }
  }, [
    yearValue,
    groupValue,
    setYearValue,
    setGroupValue,
    archiveLists,
    archiveGroupPlaylists,
  ])

  const playlistId = useMemo(() => {
    if (queryParam === "gedeeld" || !archiveGroupPlaylists) {
      return null
    }
    if (queryParam === "kids" && archiveGroupPlaylists.kidsPlaylist?.id) {
      return archiveGroupPlaylists.kidsPlaylist.id
    }
    const paramVal = queryParam ? parseInt(queryParam) : undefined
    if (
      paramVal &&
      (archiveGroupPlaylists.otherPlaylists.some((p) => p.id === paramVal) ||
        archiveGroupPlaylists.kidsPlaylist?.id === paramVal)
    ) {
      return paramVal
    }
    return archiveGroupPlaylists.otherPlaylists[0]?.id || null
  }, [archiveGroupPlaylists, queryParam])

  return (
    <>
      <Dialog.Portal forceMount>
        <Dialog.Content
          forceMount
          onPointerDownOutside={(e) => {
            if (!e.target) return
            if (!(e.target instanceof Element)) return
            if (isInsideToolbar(e)) e.preventDefault()
            const radioElement = document.querySelector("#radiobox")
            const radioVolumeElement = document.querySelector("#radioboxVolume")
            if (radioElement && radioElement.contains(e.target))
              e.preventDefault()
            if (radioVolumeElement && radioVolumeElement.contains(e.target))
              e.preventDefault()
          }}
        >
          <Root
          // initial={{ y: -1000, opacity: 0 }}
          // animate={{ y: 0, opacity: 1 }}
          // exit={{ y: -1000, opacity: 0 }}
          // transition={{
          //   ease: "backOut",
          //   duration: 0.6,
          // }}
          >
            <>
              <SideBarRoot>
                <Header>
                  <Group>
                    <BookmarkTitle>
                      <Value name="apps.web.src.schooljaren.components.archivemodal.archief">
                        ARCHIEF
                      </Value>
                    </BookmarkTitle>
                  </Group>
                  <Stack gap={"4px"}>
                    <SelectInput
                      open={yearSelectOpen}
                      setOpen={setYearSelectOpen}
                      value={yearValue}
                      onValueChange={setYearValue}
                      placeholder={
                        <Value name="apps.web.src.schooljaren.components.archivemodal.selecteer_een_schooljaar">
                          Selecteer een schooljaar…
                        </Value>
                      }
                    >
                      <Select.Group>
                        <SelectLabel>
                          <Value name="apps.web.src.schooljaren.components.archivemodal.schooljaren">
                            Schooljaren
                          </Value>
                        </SelectLabel>
                        <SelectSeparator />
                        {archiveLists?.map((archiveYear) => (
                          <StyledItem value={`${archiveYear.id}`}>
                            <Select.ItemText>{`Schooljaar ${archiveYear.displayTitle}`}</Select.ItemText>
                            <StyledItemIndicator>
                              <TbCaretRightFilled size={16} />
                            </StyledItemIndicator>
                          </StyledItem>
                        ))}
                      </Select.Group>
                    </SelectInput>
                    <SelectInput
                      open={groupSelectOpen}
                      setOpen={setGroupSelectOpen}
                      value={groupValue}
                      onValueChange={setGroupValue}
                      disabled={!yearValue}
                    >
                      <Select.Group>
                        <SelectLabel>
                          <Value name="apps.web.src.schooljaren.components.archivemodal.groepen">
                            Groepen
                          </Value>
                        </SelectLabel>
                        <SelectSeparator />
                        {selectedYearGroupList.map((group) => (
                          <StyledItem value={`${group.id}`}>
                            <Select.ItemText>{`${group.displayTitle}`}</Select.ItemText>
                            <StyledItemIndicator>
                              <TbCaretRightFilled size={16} />
                            </StyledItemIndicator>
                          </StyledItem>
                        ))}
                      </Select.Group>
                    </SelectInput>
                  </Stack>
                </Header>
                <Separator />
                {selectedGroup && (
                  // <ArchiveModalPlaylists selectedGroup={selectedGroup} />
                  <ScrollArea>
                    <Accordion.Root
                      type="multiple"
                      defaultValue={["lijsten", "school"]}
                    >
                      {/* <Accordion.Item value="gedeeld">
                                <Accordion.Header>
                                  <Item
                                    onClick={() => {
                                      setOpen(MODAL_QUERY_SHARE_TYPE_VALUE)
                                    }}
                                    isActive={
                                      queryParam ===
                                      MODAL_QUERY_SHARE_TYPE_VALUE
                                    }
                                  >
                                    <Group align="center" gap="9px">
                                      <Value name="apps.web.src.schooljaren.components.archivemodal.gedeeld_met_mij">
                                        Gedeeld met mij
                                      </Value>
                                    </Group>
                                    <TbChevronRight size={18} />
                                  </Item>
                                </Accordion.Header>
                                <Accordion.Content />
                              </Accordion.Item> */}
                      {/* <Accordion.Item value="bulletin">
                              <Accordion.Header>
                                <Item
                                // onClick={() => {}}
                                // isActive={}
                                >
                                  <Group align="center" gap="9px">
                                    <Value name="apps.web.src.playlists.components.archivemodal.prikbord">
                                      Prikbord
                                    </Value>
                                  </Group>
                                  <TbChevronRight size={18} />
                                </Item>
                              </Accordion.Header>
                              <Accordion.Content />
                            </Accordion.Item> */}
                      <Accordion.Item value="kids">
                        <Accordion.Header>
                          {archiveGroupPlaylists?.kidsPlaylist ? (
                            <Item
                              onClick={() => {
                                setOpen(MODAL_QUERY_KIDS_TYPE_VALUE)
                              }}
                              isActive={
                                playlistId ===
                                archiveGroupPlaylists.kidsPlaylist.id
                              }
                            >
                              <Group align="center" gap="9px">
                                <Value name="apps.web.src.schooljaren.components.archivemodal.kidspagina">
                                  Kidspagina
                                </Value>
                                {/* <SoonBadge /> */}
                              </Group>
                              <TbChevronRight size={18} />
                            </Item>
                          ) : null}
                        </Accordion.Header>
                        <Accordion.Content />
                      </Accordion.Item>
                      <Accordion.Item value="lijsten">
                        <Accordion.Header>
                          <Accordion.Trigger asChild>
                            <Item>
                              <Value name="apps.web.src.schooljaren.components.archivemodal.groepslijsten">
                                Groepslijsten
                              </Value>
                              <AccordionChevron size={18} />
                            </Item>
                          </Accordion.Trigger>
                        </Accordion.Header>
                        <Accordion.Content>
                          <SubItems>
                            <Stack>
                              {archiveGroupPlaylists?.otherPlaylists.map(
                                (playlist) => {
                                  return (
                                    <SubItem
                                      isActive={playlist.id === playlistId}
                                      onClick={() => {
                                        setOpen(`${playlist.id}`)
                                      }}
                                    >
                                      <PlaylistMenuCircle
                                        color={getUserPlaylistColor(playlist)}
                                        accessLevel={playlist.accessLevel}
                                        type={playlist.type}
                                      />
                                      {getUserPlaylistTitle(playlist)}
                                    </SubItem>
                                  )
                                }
                              )}
                            </Stack>
                          </SubItems>
                        </Accordion.Content>
                      </Accordion.Item>
                      {/* {archiveGroupPlaylists &&
                              !!archiveGroupPlaylists.schoolPlaylists.length ? (
                                <Accordion.Item value="school">
                                  <Accordion.Header>
                                    <Accordion.Trigger asChild>
                                      <Item>
                                        <Value name="apps.web.src.schooljaren.components.archivemodal.schoollijsten">
                                          Schoollijsten
                                        </Value>
                                        <AccordionChevron size={18} />
                                      </Item>
                                    </Accordion.Trigger>
                                  </Accordion.Header>
                                  <Accordion.Content>
                                    <SubItems>
                                      <Stack>
                                        {archiveGroupPlaylists.schoolPlaylists.map(
                                          (playlist) => {
                                            return (
                                              <SubItem
                                                isActive={
                                                  playlist.id === playlistId
                                                }
                                                onClick={() => {
                                                  setOpen(`${playlist.id}`)
                                                }}
                                              >
                                                <PlaylistMenuCircle
                                                  color={GREEN_COLOR}
                                                  accessLevel={
                                                    playlist.accessLevel
                                                  }
                                                  type={playlist.type}
                                                />
                                                {
                                                  playlist.playlist
                                                    ?.displayTitle
                                                }
                                              </SubItem>
                                            )
                                          }
                                        )}
                                      </Stack>
                                    </SubItems>
                                  </Accordion.Content>
                                </Accordion.Item>
                              ) : null} */}
                    </Accordion.Root>
                  </ScrollArea>
                )}
                <Separator />
                <Footer>
                  {selectedGroup && (
                    <MigrateGroupModal
                      groupId={selectedGroup.id}
                      onClose={() => setIsArchiveModalOpen(false)}
                    />
                  )}
                </Footer>
              </SideBarRoot>
            </>
            <Right>
              <Suspense>
                {playlistId && selectedGroup && (
                  <ArchiveModalContent
                    id={playlistId}
                    key={playlistId}
                    grades={grades}
                    onClose={() => setOpen(null)}
                    setIsAddLessonOpen={setIsAddLessonOpen}
                    selectedGroup={selectedGroup}
                  />
                )}
              </Suspense>
            </Right>
            <Dialog.Close asChild>
              <ModalClose>
                <FaTimes />
              </ModalClose>
            </Dialog.Close>
          </Root>
        </Dialog.Content>
        <Overlay
        // initial={{ opacity: 0 }}
        // animate={{ opacity: 1 }}
        // exit={{ opacity: 0 }}
        />
      </Dialog.Portal>
    </>
  )
}
const ArchiveModal = () => {
  const [queryParam, setOpen] = useRouterQueryParam("archief", "string")
  const open = queryParam !== undefined

  return (
    <Dialog.Root
      open={open !== undefined}
      onOpenChange={(val) => setOpen(val ? "" : null)}
    >
      {open && <InnerArchiveModal />}
    </Dialog.Root>
  )
}

export default ArchiveModal
