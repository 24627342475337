import styled from "@emotion/styled"

export const EmptyContainer = styled.div`
  background-color: #efefef;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  height: 100%;
  box-sizing: border-box;
`

export const EmptyImage = styled.div`
  width: 100%;
  height: 100%;
  background-color: #d9d9d9;
  border-radius: 4px;
`
