import { invalidateQuery, useMutation } from "@blitzjs/rpc"
import * as AlertDialog from "@radix-ui/react-alert-dialog"
import { Value } from "@stringtale/react"
import { useCurrentGroup } from "app/core/hooks/useCurrentGroup"
import useToast from "app/toasts/hooks/useToast"
import Hr from "ui/Hr"
import * as Modal from "ui/Modal"
import Stack from "ui/Stack"
import Text from "ui/Text"
import deleteUserPlaylist from "../mutations/deleteUserPlaylist"
import getGroupedUserPlaylists from "../queries/getGroupedUserPlaylists"
import { GetUserListResult } from "../queries/getUserList"
import styled from "@emotion/styled"
import Group from "ui/Group"
import { LIGHT_GREY_COLOR } from "theme/colors"
import Radio from "ui/Radio"
import { ReactNode, useId, useState } from "react"

const Root = styled(Group)`
  align-items: center;
  border: 2px solid ${LIGHT_GREY_COLOR};
  border-radius: 8px;
  padding: 24px;
`

const Item = ({ title, value }: { title: ReactNode; value: string }) => {
  const id = useId()
  return (
    <Root>
      <Group gap="8px">
        <Radio id={id} value={value} />
        <label htmlFor={id}>{title}</label>
      </Group>
    </Root>
  )
}

type Mode = "all" | "current"

export default function DeleteMultiplePlaylistsModal({
  userList,
}: {
  userList: NonNullable<GetUserListResult>
}) {
  const currentGroup = useCurrentGroup()
  const addToast = useToast()
  const [deletePlaylistMutation] = useMutation(deleteUserPlaylist)
  const [mode, setMode] = useState<Mode | null>(null)
  return (
    <AlertDialog.Portal>
      <AlertDialog.Overlay asChild>
        <Modal.Overlay isAlert />
      </AlertDialog.Overlay>
      <AlertDialog.Content asChild>
        <Modal.Root isAlert>
          <Modal.Content>
            <Stack gap="24px">
              <Stack gap="10px">
                <Stack gap="24px">
                  <Modal.Title>
                    <Value
                      name="apps.web.src.playlists.components.playlistmodalheader.verwijderen.multi.title"
                      format={{
                        currentGroup:
                          currentGroup?.displayTitle || "huidige groep",
                      }}
                    >
                      {`Wil je deze lijst van alle groepen, of alleen van {currentGroup} verwijderen?`}
                    </Value>
                  </Modal.Title>
                  <Hr />
                  <Text>
                    <Value name="apps.web.src.playlists.components.verwijderen.multiple.body">
                      Let op! Het verwijderen van een afspeellijst is permanent,
                      en kan niet ongedaan gemaakt worden.
                    </Value>
                  </Text>
                </Stack>
                <Stack gap="8px">
                  <Radio.Group
                    value={mode || ""}
                    onValueChange={(v) => setMode(v as Mode)}
                  >
                    <Item
                      title={
                        <Value name="apps.web.src.playlists.components.deletemultipleplaylistsmodal.verwijder_bij_alle_groepen">
                          Verwijder bij alle groepen
                        </Value>
                      }
                      value="all"
                    />
                    <Item
                      title={
                        <Value
                          name="apps.web.src.playlists.components.deletemultipleplaylistsmodal.verwijder_uit_currentgroup_displaytitle"
                          format={{ groupName: currentGroup?.displayTitle }}
                        >
                          {`Verwijder uit {groupName}`}
                        </Value>
                      }
                      value="current"
                    />
                  </Radio.Group>
                </Stack>
              </Stack>
              <Modal.Buttons>
                <AlertDialog.Cancel asChild>
                  <Modal.CancelButton>
                    <Value name="playlists.verwijderen.multiple.annuleren">
                      Annuleer
                    </Value>
                  </Modal.CancelButton>
                </AlertDialog.Cancel>
                <AlertDialog.Action asChild>
                  <Modal.SubmitButton
                    disabled={!mode}
                    onClick={async () => {
                      await deletePlaylistMutation({
                        id: userList.id,
                        mode: mode || undefined,
                      })
                      await invalidateQuery(getGroupedUserPlaylists)
                      addToast({
                        content: (
                          <Value name="playlists.verwijderen.multiple.current_success">
                            Afspeellijst verwijderd
                          </Value>
                        ),
                      })
                    }}
                  >
                    <Value name="playlists.verwijderen.multiple.delete_current_group">
                      Verwijder lijst
                    </Value>
                  </Modal.SubmitButton>
                </AlertDialog.Action>
              </Modal.Buttons>
            </Stack>
          </Modal.Content>
          <AlertDialog.Cancel asChild>
            <Modal.Close />
          </AlertDialog.Cancel>
        </Modal.Root>
      </AlertDialog.Content>
    </AlertDialog.Portal>
  )
}
