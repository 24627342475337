
export const MODAL_QUERY_KEY = "bibliotheek"
export const MODAL_QUERY_SHARE_LIST_KEY = "list"
export const MODAL_QUERY_KIDS_TYPE_VALUE = "kids"
export const MODAL_QUERY_SHARE_TYPE_VALUE = "gedeeld"
export const MODAL_QUERY_BULLETIN_BOARD_TYPE_VALUE = "prikbord"

export const RADIUS = "16px"

export const ACCESS_LEVELS = {
  PERSONAL: "Mijn lijst",
  GROUP: "Groepslijst",
} as const


export const SORT_OPTIONS = [
  "CUSTOM",
  "DATE_DESC",
  "DATE_ASC",
  "TITLE_ASC",
  "TITLE_DESC",
] as const

export const SORT_OPTIONS_DISPLAY = {
  CUSTOM: "Mijn volgorde",
  DATE_ASC: "Oud - Nieuw",
  DATE_DESC: "Nieuw - Oud",
  TITLE_ASC: "Titel (A-Z)",
  TITLE_DESC: "Titel (Z-A)",
}

export type SortOption = typeof SORT_OPTIONS[number]
