import { Value } from "@stringtale/react"
import { useQuery } from "@blitzjs/rpc"
import styled from "@emotion/styled"
import getContentSubjectTitle from "app/content-subjects/getContentSubjectTitle"
import {} from "app/learning-lines/components/LearningLineGradeText"
import { ContentSubjectType, LessonType } from "db"
import { ComponentProps, Suspense, useMemo } from "react"
import { DIMMED_BLUE_COLOR } from "theme/colors"
import Text from "ui/Text"
import getLearningLineBelongsToLesson from "../queries/getLearningLineBelongsToLesson"
import getHasSubmittable, {
  GetHasSubmittableLesson,
} from "../utils/getHasSubmittable"
import getLessonTypeTitle from "../utils/getLessonTypeTitle"
import { useIsPublic } from "app/core/hooks/useIsPublic"

export type LearningLine = {
  period: string | null
  cardLabel: string | null
}

export type LessonSubTitleLesson = GetHasSubmittableLesson & {
  id?: number
  contentSubject: ContentSubjectType
  lessonType: LessonType
  typeTitle: string | null
  isLearningLineLesson: boolean
}

const Root = styled(Text)`
  display: flex;
  gap: 5px;
  color: ${DIMMED_BLUE_COLOR};
`

type Props = {
  lesson: LessonSubTitleLesson
  learningLine?: LearningLine | null
  size?: string
} & ComponentProps<typeof Root>

export const LessonSubTitleLoader = (
  props: Omit<Props, "learningLine"> & { lesson: { id: number } }
) => {
  const [learningLine] = useQuery(getLearningLineBelongsToLesson, {
    lessonId: props.lesson.id,
  })
  return (
    <Suspense>
      <LessonSubTitle {...props} learningLine={learningLine} />
    </Suspense>
  )
}

export default function LessonSubTitle({
  lesson,
  learningLine,
  ...props
}: Props) {
  const hasSubmittable = useMemo(() => getHasSubmittable(lesson), [lesson])
  const isPublic = useIsPublic()
  return (
    <Root {...props}>
      {lesson.isLearningLineLesson ? (
        <Value name="apps.web.src.lessons.components.lessonsubtitle.leerlijn">
          Leerlijn
        </Value>
      ) : null}{" "}
      {getContentSubjectTitle(lesson.contentSubject)} <span>|</span>{" "}
      {lesson.isLearningLineLesson && learningLine ? (
        <>
          {learningLine.cardLabel} <span>|</span>{" "}
        </>
      ) : null}{" "}
      {lesson.typeTitle || getLessonTypeTitle(lesson.lessonType)}{" "}
      {hasSubmittable && !isPublic ? (
        <>
          <span>|</span>{" "}
          <Value name="apps.web.src.lessons.components.lessonsubtitle.inleveropdracht">
            Inleveropdracht
          </Value>
        </>
      ) : null}
    </Root>
  )
}
