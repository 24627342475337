import { Routes } from "@blitzjs/next"
import styled from "@emotion/styled"
import { PiVinylRecordBold } from "@react-icons/all-files/pi/PiVinylRecordBold"
import { TbBookmark } from "@react-icons/all-files/tb/TbBookmark"
import { TbSchool } from "@react-icons/all-files/tb/TbSchool"
import { TbChalkboard } from "@react-icons/all-files/tb/TbChalkboard"
import { TbCategory } from "@react-icons/all-files/tb/TbCategory"
import { TbHome } from "@react-icons/all-files/tb/TbHome"
import { TbMenu2 } from "@react-icons/all-files/tb/TbMenu2"
import { TbMoodSmile } from "@react-icons/all-files/tb/TbMoodSmile"
import { TbCalendarEvent } from "@react-icons/all-files/tb/TbCalendarEvent"
import { TbSearch } from "@react-icons/all-files/tb/TbSearch"
import { TbSettings } from "@react-icons/all-files/tb/TbSettings"
import { Value } from "@stringtale/react"
import useRouterQueryParam from "app/core/hooks/useRouterQueryParam"
import { MODAL_QUERY_KEY } from "app/playlists/consts"
import { Subscription, Group as TGroup } from "db"
import Image from "next/image"
import Link from "next/link"
import {
  HEADER_Z_INDEX,
  LIGHT_GREY_COLOR,
  LIGHT_ORANGE_COLOR,
  ORANGE_COLOR,
  PX32,
} from "theme/consts"
import Container from "ui/Container"
import Group from "ui/Group"
import { File } from "utils/files/types"
import HeaderUserBase from "./HeaderUser"
import KidsMenu from "./KidsMenu"
import LessonMenu from "./LessonMenu"
import ButtonBase, { Content as ButtonContent } from "./LineButton"
import MobileMenuDrawer from "./MobileMenuDrawer"
import { css } from "@emotion/react"
import LearninglineMenu from "./LearninglineMenu"
import CategoryMenu from "./CategoryMenu"
import { useRouter } from "next/router"

const LG = "1024px"
// const MD = "768px"
const SM = "640px"

export const Root = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: ${HEADER_Z_INDEX};
  box-shadow: 0px 3px 4px #00000005;
  background: white;
  box-sizing: border-box;
  border-bottom: 1px solid ${LIGHT_GREY_COLOR};

  @media print {
    display: none;
  }
`

export const Content = styled(Container)`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  position: relative;
  overflow: visible;
  min-height: 79px;
  @media (max-width: ${SM}) {
    box-sizing: border-box;
    min-height: 56px;
    align-items: center;
  }
  li {
    list-style: none;
  }
`

export const LogoRoot = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${SM}) {
    img {
      width: 46px;
      height: 35px;
    }
  }
`

export const Button = styled(ButtonBase)<{ size?: string }>`
  @media (max-width: ${SM}) {
    border: none;
    padding: 0;
    ${ButtonContent} {
      display: none !important;
    }
  }
`

export const SizeButton = styled(ButtonBase)<{
  size?: string
  scale?: "LARGE" | "SMALL"
}>`
  box-sizing: border-box;
  ${({ scale }) =>
    scale === "LARGE"
      ? css`
          max-width: 118px;
          @media (max-width: 1110px) {
            max-width: 93px;
          }
        `
      : css`
          width: 88px;
        `}

  @media (max-width: ${(p) => p.size || LG}) {
    display: none;
  }
`

export const MenuButton = styled(Button)<{ size?: string }>`
  width: 88px;
  @media (min-width: calc(${LG} + 1px)) {
    display: none;
  }
`

export const User = styled(HeaderUserBase)`
  @media (max-width: ${LG}) {
    display: none;
  }
`

export function Logo() {
  return (
    <LogoRoot>
      <Link {...Routes.Home()}>
        <Image src={"/123Zing_logo.svg"} width={72} height={55} alt="Logo" />
      </Link>
    </LogoRoot>
  )
}

const HeaderLogo = styled.div`
  position: absolute;
  opacity: 1;
  transition: opacity 100ms ease-in-out;
`

const HomeButton = styled.div`
  position: absolute;
  opacity: 0;
  border-radius: 8px;
  background-color: ${LIGHT_ORANGE_COLOR};
  transition: opacity 100ms ease-in-out;
  height: 68px;
`

const FadingLogo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: 46px;

  @media (min-width: calc(${SM} + 1px)) {
    width: 100px;
    &:hover {
      ${HomeButton} {
        opacity: 1;
      }
      ${HeaderLogo} {
        opacity: 0;
      }
    }
  }
`

export const Buttons = styled.div`
  display: flex;
  align-items: stretch;
  flex-shrink: 0;
  border-right: 1px solid ${LIGHT_GREY_COLOR};
  @media (max-width: ${SM}) {
    border: none;
    gap: 13px;
  }
`

export const ButtonIcon = styled.div`
  font-size: ${PX32};
  color: ${ORANGE_COLOR};
  [data-state="open"] > & {
    color: white;
  }
`

type Props = {
  user?: {
    id: number
    firstName: string | null
    insertion: string | null
    lastName: string | null
    avatar: File | null
    role: string
    organizationUsers: { onboardingType: string; role: string }[]
  } | null
  groups: TGroup[] | null
  group?: {
    id: number
    displayTitle: string
  }
  organization: {
    subscription: Subscription
  } | null
}

export default function Header({ user, group, groups, organization }: Props) {
  const [open, setOpen] = useRouterQueryParam(MODAL_QUERY_KEY, "boolean")
  const router = useRouter()
  return (
    <>
      <Root>
        <Content>
          <Group align="stretch">
            <FadingLogo>
              <HeaderLogo>
                <Logo />
              </HeaderLogo>
              <HomeButton>
                <SizeButton
                  size={"0px"}
                  scale={"SMALL"}
                  style={{
                    borderLeft: "none",
                    height: "68px",
                    borderRadius: "8px",
                  }}
                  as={Link}
                  {...Routes.Home()}
                  top={
                    <ButtonIcon>
                      <TbHome />
                    </ButtonIcon>
                  }
                >
                  <Value name="header.button.home">HOME</Value>
                </SizeButton>
              </HomeButton>
            </FadingLogo>
            <Buttons>
              <LearninglineMenu
                trigger={
                  <SizeButton
                    size={LG}
                    scale={"LARGE"}
                    top={
                      <ButtonIcon>
                        <TbChalkboard aria-hidden />
                      </ButtonIcon>
                    }
                  >
                    <Value name="header.button.lesmateriaal">LEERLIJNEN</Value>
                  </SizeButton>
                }
              />
              <CategoryMenu
                trigger={
                  <SizeButton
                    size={LG}
                    scale={"LARGE"}
                    top={
                      <ButtonIcon>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="32"
                          height="32"
                          viewBox="0 -1 32 32"
                        >
                          <defs>
                            <clipPath id="clip-path">
                              <rect
                                id="Rectangle_149601"
                                data-name="Rectangle 149601"
                                width="32"
                                height="32"
                                transform="translate(3662 1015)"
                                fill="#fff"
                                strokeWidth="3"
                              />
                            </clipPath>
                          </defs>
                          <g
                            id="Mask_Group_21364"
                            data-name="Mask Group 21364"
                            transform="translate(-3662 -1015)"
                            clipPath="url(#clip-path)"
                          >
                            <g id="category" transform="translate(3662 1015)">
                              <path
                                id="Path_22523"
                                data-name="Path 22523"
                                d="M18.667,22.667a4,4,0,1,0,4-4,3.929,3.929,0,0,0-4,4"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="3"
                              />
                              <path
                                id="Path_22524"
                                data-name="Path 22524"
                                d="M18.667,9.333a4,4,0,0,0,8,0,4,4,0,1,0-8,0"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="3"
                              />
                              <path
                                id="Path_22525"
                                data-name="Path 22525"
                                d="M5.333,9.333a3.929,3.929,0,0,0,4,4,3.929,3.929,0,0,0,4-4,3.929,3.929,0,0,0-4-4,3.929,3.929,0,0,0-4,4"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="3"
                              />
                              <path
                                id="Path_22526"
                                data-name="Path 22526"
                                d="M5.333,22.667a3.929,3.929,0,0,0,4,4,4,4,0,0,0,0-8,3.929,3.929,0,0,0-4,4"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="3"
                              />
                            </g>
                          </g>
                        </svg>
                      </ButtonIcon>
                    }
                  >
                    <Value name="header.button.categorieen">CATEGORIEËN</Value>
                  </SizeButton>
                }
              />
              <SizeButton
                size={LG}
                onClick={async () => {
                  await router.push(Routes.CalendarPage())
                }}
                top={
                  <ButtonIcon>
                    <TbCalendarEvent />
                  </ButtonIcon>
                }
              >
                <Value name="header.button.calendar">KALENDER</Value>
              </SizeButton>
              {group ? (
                <KidsMenu
                  trigger={
                    <SizeButton
                      size={LG}
                      top={
                        <ButtonIcon>
                          <TbMoodSmile />
                        </ButtonIcon>
                      }
                    >
                      <Value name="header.button.kids">KIDS</Value>
                    </SizeButton>
                  }
                />
              ) : null}

              {(organization?.subscription.hasAccessToComposingTools ||
                user?.role === "ADMIN") && (
                <SizeButton
                  as={Link}
                  {...Routes.StudioPage()}
                  top={
                    <ButtonIcon>
                      <PiVinylRecordBold />
                    </ButtonIcon>
                  }
                >
                  <Value name="header.button.studio">STUDIO</Value>
                </SizeButton>
              )}
            </Buttons>
          </Group>
          <Buttons>
            {user?.role === "ADMIN" && (
              <SizeButton
                top={
                  <ButtonIcon>
                    <TbSettings />
                  </ButtonIcon>
                }
                as={Link}
                {...Routes.AdminLessonPage()}
              >
                <Value name="apps.web.src.header.components.header.cms">
                  CMS
                </Value>
              </SizeButton>
            )}

            <SizeButton
              size={LG}
              scale={"LARGE"}
              as={Link}
              {...Routes.LeerkrachtIndexPage()}
              top={
                <ButtonIcon>
                  <TbSchool />
                </ButtonIcon>
              }
            >
              <Value name="header.button.teacher">LEERKRACHT</Value>
            </SizeButton>

            <SizeButton
              size={LG}
              scale={"LARGE"}
              top={
                <ButtonIcon>
                  <TbBookmark />
                </ButtonIcon>
              }
              onClick={() => setOpen(true)}
            >
              <Value name="header.button.lijsten" version="1">
                BIBLIOTHEEK
              </Value>
            </SizeButton>
            <SizeButton
              size={SM}
              top={
                <ButtonIcon>
                  <TbSearch />
                </ButtonIcon>
              }
              as={Link}
              {...Routes.Zoeken()}
            >
              <Value name="header.button.zoeken">ZOEKEN</Value>
            </SizeButton>
            <MobileMenuDrawer
              groups={groups}
              trigger={
                <MenuButton
                  top={
                    <ButtonIcon>
                      <TbMenu2 />
                    </ButtonIcon>
                  }
                  // as={Link}
                  // {...Routes.Zoeken()}
                >
                  <Value name="header.button.menu">MENU</Value>
                </MenuButton>
              }
            />
            {user && <User user={user} group={group} groups={groups} />}
          </Buttons>
        </Content>
      </Root>
    </>
  )
}
