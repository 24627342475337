import { Value } from "@stringtale/react"
import { invalidateQuery, useMutation, useQuery } from "@blitzjs/rpc"
import getUserPlaylists from "app/home/queries/getUserPlaylists"
import getHasSubmittable, {
  GetHasSubmittableLesson,
} from "app/lessons/utils/getHasSubmittable"
import SelectPlaylistModalBase from "app/playlists/components/SelectPlaylistModal"
import addLessonsToPlaylist from "app/playlists/mutations/addLessonToPlaylist"
import useToast from "app/toasts/hooks/useToast"
import { Dispatch, ReactNode, SetStateAction, Suspense, useState } from "react"
import Text from "ui/Text"
import getUserPlaylistsWithLesson from "../../search/queries/getUserPlaylistsWithLesson"
import * as Dialog from "@radix-ui/react-dialog"
import { AddSubmittableToPlaylistWarning } from "./AddSubmittableToPlaylistWarning"
import getBulletinBoardItems from "app/bulletinboard/queries/getBulletinBoardItems"

export type AddToPlaylistLesson = {
  id: number
  displayTitle: string | null
}

export type AddToPlaylistControlledProps = {
  lessons: Array<AddToPlaylistLesson>
}

export type AddToPlaylistProps = AddToPlaylistControlledProps & {
  onClose: () => void
  setSubmittableToKidsListWarningOpen: (val: boolean) => void
}

export const AddToPlaylistModal = ({
  lessons,
  onClose,
  setSubmittableToKidsListWarningOpen,
}: AddToPlaylistProps) => {
  const addToast = useToast()
  const [addLessonToPlaylistMutation] = useMutation(addLessonsToPlaylist)
  const [userPlaylists] = useQuery(getUserPlaylists, {
    type: "ADD",
  })

  const lessonIds = lessons.map((l) => l.id)

  const [usedUserPlaylist] = useQuery(getUserPlaylistsWithLesson, {
    ids: lessonIds,
  })

  return (
    <SelectPlaylistModalBase
      multiple
      title={
        <Value name="apps.web.src.playlists.components.addtoplaylistmodal.toevoegen_aan_afspeellijst">
          Toevoegen aan afspeellijst
        </Value>
      }
      body={
        <Text>
          <Value name="apps.web.src.playlists.components.addtoplaylistmodal.selecteer_n_of_meerdere_afspeellijsten_om_een_les_of_lied_aan_toe_te_voegen">
            Selecteer één of meerdere afspeellijsten om een les of lied aan toe
            te voegen.
          </Value>
        </Text>
      }
      playlists={userPlaylists}
      onSubmit={async (playlistIds) => {
        const { hasAddedToKidsList, hasSubmittable } =
          await addLessonToPlaylistMutation({
            playlistIds,
            lessonIds,
          })
        await invalidateQuery(getBulletinBoardItems)
        await invalidateQuery(getUserPlaylistsWithLesson)
        addToast({
          content: (
            <>
              <Text>
                <Value
                  format={{
                    count: playlistIds.length,
                  }}
                  name="apps.web.src.playlists.components.addtoplaylistmodal.les_toegevoegd_aan"
                >
                  {`Les toegevoegd aan {count, plural,
                    =1 {afspeellijst}
                    other {afspeellijsten}
                  }`}
                </Value>
              </Text>
            </>
          ),
        })
        onClose()
        if (hasAddedToKidsList && hasSubmittable) {
          setSubmittableToKidsListWarningOpen(true)
        }
      }}
      getIsDisabled={(userPlaylist) => {
        return usedUserPlaylist.some(
          ({ id, playlist }) =>
            userPlaylist.id === id &&
            playlist?._count.lessons === lessonIds.length
        )
      }}
    />
  )
}

function AddToPlaylistModalUnControlled({
  trigger,
  isOpen,
  setOpen,
  ...props
}: AddToPlaylistControlledProps & {
  trigger?: ReactNode
  isOpen: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}) {
  const [
    isSubmittableToKidsListWarningOpen,
    setSubmittableToKidsListWarningOpen,
  ] = useState(false)
  return (
    <>
      <Dialog.Root
        open={isSubmittableToKidsListWarningOpen}
        onOpenChange={setSubmittableToKidsListWarningOpen}
      >
        <Suspense>
          {isSubmittableToKidsListWarningOpen ? (
            <AddSubmittableToPlaylistWarning lessons={props.lessons} />
          ) : null}
        </Suspense>
      </Dialog.Root>
      <Dialog.Root open={isOpen} onOpenChange={setOpen}>
        {trigger ? <Dialog.Trigger asChild>{trigger}</Dialog.Trigger> : null}
        {isOpen ? (
          <Suspense>
            <AddToPlaylistModal
              {...props}
              onClose={() => setOpen(false)}
              setSubmittableToKidsListWarningOpen={
                setSubmittableToKidsListWarningOpen
              }
            />
          </Suspense>
        ) : null}
      </Dialog.Root>
    </>
  )
}

function AddToPlaylistModalControlled(
  props: AddToPlaylistControlledProps & {
    trigger: ReactNode
  }
) {
  const [isOpen, setOpen] = useState(false)
  return (
    <AddToPlaylistModalUnControlled
      {...props}
      isOpen={isOpen}
      setOpen={setOpen}
    />
  )
}

AddToPlaylistModal.Controlled = AddToPlaylistModalControlled
AddToPlaylistModal.UnControlled = AddToPlaylistModalUnControlled

export default AddToPlaylistModal //AddToPlaylistModal
