import { useQuery } from "@blitzjs/rpc"
import styled from "@emotion/styled"
import { Value } from "@stringtale/react/dist"
import getContentSubjects from "app/content-subjects/queries/getContentSubjects"
import { useCurrentOrganization } from "app/core/hooks/useCurrentOrganization"
import { ContentSubject, ContentSubjectType } from "db"
import { Dispatch, SetStateAction, useId } from "react"
import { ORANGE_COLOR } from "theme/colors"
import { PX14 } from "theme/sizes"
import Checkbox from "ui/Checkbox"
import Group from "ui/Group"
import Stack from "ui/Stack"
import toggleArray from "utils/global/toggleArray"

const Header = styled.h2`
  font-weight: 700;
  font-size: ${PX14};
`

const GroupRoot = styled(Group)`
  line-height: 1;
`

const Label = styled.label`
  &:hover {
    color: ${ORANGE_COLOR};
  }
`

const Items = styled(Stack)`
  margin-left: 8px;
`

const ContentSubjectCheckbox = ({
  contentSubject,
  vakken,
  setVakken,
}: {
  contentSubject: ContentSubject
  vakken: ContentSubjectType[]
  setVakken: Dispatch<SetStateAction<ContentSubjectType[]>>
}) => {
  const id = useId()
  const isActive = vakken.includes(contentSubject.type)
  return (
    <GroupRoot gap="8px" align="center">
      <Checkbox
        id={id}
        checked={isActive}
        onCheckedChange={() => {
          setVakken(toggleArray(contentSubject.type))
        }}
      />
      <Label htmlFor={id}>{contentSubject.displayTitle}</Label>
    </GroupRoot>
  )
}

type Props = {
  vakken: ContentSubjectType[]
  setVakken: Dispatch<SetStateAction<ContentSubjectType[]>>
}

export default function ContentSubjectFilter({ vakken, setVakken }: Props) {
  const currentSchool = useCurrentOrganization()
  const [contentSubjects] = useQuery(getContentSubjects, undefined)
  const subscription = currentSchool?.subscription
  return (
    <Stack gap="7px">
      <Header>
        <Value name="apps.web.src.themes.components.themeplaylist.methode">
          Methode
        </Value>
      </Header>
      <Items gap={"8px"}>
        {contentSubjects.map((contentSubject) => {
          if (contentSubject.type === "DRAMA" && subscription?.isDramaHidden) {
            return null
          }
          if (contentSubject.type === "DANCE" && subscription?.isDanceHidden) {
            return null
          }
          return (
            <ContentSubjectCheckbox
              contentSubject={contentSubject}
              vakken={vakken}
              setVakken={setVakken}
            />
          )
        })}
      </Items>
    </Stack>
  )
}
