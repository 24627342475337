import styled from "@emotion/styled"
import * as Radix from "@radix-ui/react-checkbox"
import { ComponentProps, ReactNode, forwardRef, useId } from "react"
import { FaCheck } from "@react-icons/all-files/fa/FaCheck"
import { DARKER_GREY_COLOR, ORANGE_COLOR } from "theme/colors"

const Intermediate = styled.div`
  width: 8px;
  height: 2px;
  background: #ffffff;
  border-radius: 3px;
  opacity: 1;
`

const Root = styled(Radix.Root)<{ size?: "medium" | "large" }>`
  width: ${(p) => (p.size === "large" ? "19px" : "16px")};
  height: ${(p) => (p.size === "large" ? "19px" : "16px")};
  border-radius: 4px;
  border: 1px solid #03004e80;
  display: flex;
  align-items: center;
  justify-content: center;
  &[data-state="checked"],
  &[data-state="indeterminate"] {
    background: ${ORANGE_COLOR};
    border-color: ${ORANGE_COLOR};
  }
  :hover {
    border-color: ${ORANGE_COLOR};
  }
  :disabled {
    opacity: 0.5;
    background: ${DARKER_GREY_COLOR};
    border-color: ${DARKER_GREY_COLOR};
  }
`

const Indicator = styled(Radix.Indicator)`
  line-height: 0;
`

export default forwardRef<HTMLButtonElement, ComponentProps<typeof Root> & { label?: ReactNode }>(
  function Checkbox({ label, ...props }, ref) {
    const id = useId()
    return (
      <>
        <Root id={id} {...props} ref={ref}>
          <Indicator>
            <FaCheck color="white" size={props.size === "large" ? 12 : 10} />
          </Indicator>
        </Root>
        {label ? <label htmlFor={id}>{label}</label> : null}
      </>
    )
  }
)
