


export const ORDER_DIR = "desc" as const

export const ORDER_BY = {
  orderBy: {
    sort: ORDER_DIR
  }
} as const


export const SUPPORT_LINKS = {
  "HOME": "https://123zing.my.site.com/knowledgebase/s/",
  "LEERKRACHT": "https://123zing.my.site.com/knowledgebase/s/",
  "CATEGORIES": "https://123zing.my.site.com/knowledgebase/s/article/hoe-werk-ik-thematisch-met-123ZING",
  "CATEGORIE_DETAIL": "https://123zing.my.site.com/knowledgebase/s/article/hoe-werk-ik-thematisch-met-123ZING",
  "THEME_DETAIL": "https://123zing.my.site.com/knowledgebase/s/article/hoe-werk-ik-thematisch-met-123ZING",
  "ZOEKEN": "https://123zing.my.site.com/knowledgebase/s/article/hoe-vind-ik-de-juiste-les",
  "LL": "https://123zing.my.site.com/knowledgebase/s/article/hoe-werken-de-123ZING-leerlijnen",
  "LL_DETAIL": "https://123zing.my.site.com/knowledgebase/s/article/hoe-werken-de-123ZING-leerlijnen",
  "LL_EXPLAIN": "https://123zing.my.site.com/knowledgebase/s/article/hoe-werken-de-123ZING-leerlijnen",
  "LES_DETAIL": "https://123zing.my.site.com/knowledgebase/s/article/hoe-geef-ik-een-les",
  "BEHEER_HOME": "https://123zing.my.site.com/knowledgebase/s/article/schoolbeheer",
  "BEHEER_GROEPEN": "https://123zing.my.site.com/knowledgebase/s/article/hoe-richt-ik-een-schoolaccount-in",
  "BEHEER_USERS": "https://123zing.my.site.com/knowledgebase/s/article/hoe-richt-ik-een-schoolaccount-in",
  "BEHEER_LISTS": "https://123zing.my.site.com/knowledgebase/s/article/hoe-gebruik-ik-de-afspeellijsten-in-de-bibliotheek",
  "STUDIO": "https://123zing.my.site.com/knowledgebase/s/article/hoe-werkt-de-componeertool-in-de-123ZING-studio",
  "KIDS_CODES": "https://123zing.my.site.com/knowledgebase/s/article/hoe-gebruik-ik-de-kidspagina",
  "KIDS_SUBMIT": "https://123zing.my.site.com/knowledgebase/s/article/wat-zijn-inleveropdrachten-en-hoe-werk-ik-daarmee",
  "SCHOOLYEAR": "https://123zing.my.site.com/knowledgebase/s/article/schooljaarovergang",
  "CONTACT_SUPPORT": "https://123zing.my.site.com/knowledgebase/s/contactsupport"
} as const