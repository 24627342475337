import { invalidateQuery, useQuery } from "@blitzjs/rpc"
import styled from "@emotion/styled"
import * as Dialog from "@radix-ui/react-dialog"
import getAllGrades from "app/grades/queries/getAllGrades"
import { AnimatePresence, motion } from "framer-motion"
import { Suspense, useEffect, useMemo, useRef, useState } from "react"
import {
  DARK_BLUE_COLOR,
  PLAYLIST_MODAL_OVERLAY_Z_INDEX,
  PLAYLIST_MODAL_Z_INDEX,
} from "theme/consts"
import { poppins } from "theme/fonts"
import { MODAL_BOX_SHADOW } from "theme/styles"
import * as Modal from "ui/Modal"
import {
  MODAL_QUERY_BULLETIN_BOARD_TYPE_VALUE,
  MODAL_QUERY_KEY,
  MODAL_QUERY_SHARE_TYPE_VALUE,
  RADIUS,
} from "../consts"
import getGroupedUserPlaylists from "../queries/getGroupedUserPlaylists"
import { PlaylistModalContent } from "./PlaylistModalContent"
import UserListModalSideBar from "./PlaylistModalSideBar"
import NewPlaylistModal from "./NewPlaylistModal"
import useRouterQueryParam from "app/core/hooks/useRouterQueryParam"
import { usePlay } from "../../radio/components/PlayerContext"
import AddLessonOverlay from "app/playlists/components/AddLessonOverlay"
import PlaylistModalShareContent from "./ShareContent"
import { isInsideToolbar } from "@stringtale/react"
import MigratePlaylistToGroupsModal from "app/playlists/components/MigratePlaylistToGroupsModal"
import { FaTimes } from "@react-icons/all-files/fa/FaTimes"
import { BulletinBoardModalContent } from "./BulletinBoardModalContent"
import getBulletinBoardItems from "app/bulletinboard/queries/getBulletinBoardItems"

const Overlay = styled(Dialog.Overlay)`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  inset: 0;
  z-index: ${PLAYLIST_MODAL_OVERLAY_Z_INDEX};
`.withComponent(motion.div)

const Root = styled.div`
  /* width: calc(100vw - 20px); */
  /* height: calc(100vh - 20px); */
  position: fixed;
  top: 25px;
  left: 25px;
  bottom: 25px;
  right: 25px;
  /* transform: translate(-50%, -50%); */
  z-index: ${PLAYLIST_MODAL_Z_INDEX};

  background-color: white;
  border-radius: ${RADIUS};
  font-family: ${poppins.style.fontFamily};
  /* overflow: hidden; */
  display: flex;
  overflow: hidden;
  overflow: clip;
  ${MODAL_BOX_SHADOW}
`.withComponent(motion.div)

const Right = styled.div`
  background-color: white;
  flex: 1;
  /* border-bottom-right-radius: ${RADIUS};
  border-top-right-radius: ${RADIUS}; */
  display: flex;
  flex-direction: column;
`

const ModalClose = styled(Modal.Close)`
  border: 2px solid #ffffff;
  background-color: ${DARK_BLUE_COLOR};
  color: white;
`

const PlaylistModal = () => {
  const [playlists] = useQuery(getGroupedUserPlaylists, undefined)
  const [grades] = useQuery(getAllGrades, undefined)
  const [isNewPlaylistOpen, setIsNewPlaylistOpen] = useState(false)
  const [isAddLessonOpen, setIsAddLessonOpen] = useState(false)
  const [queryParam, setOpen] = useRouterQueryParam(MODAL_QUERY_KEY, "string")
  const [isMigrationModalVisibe, setMigrationModalVisibe] = useState(false)

  const open = queryParam !== undefined
  const playlistId = useMemo(() => {
    if (queryParam === "gedeeld") {
      return null
    }
    if (queryParam === "kids" && playlists.kidsPlaylist?.id) {
      return playlists.kidsPlaylist.id
    }
    const paramVal = queryParam ? parseInt(queryParam) : undefined
    if (Number.isNaN(paramVal)) {
      return null
    }
    if (
      paramVal &&
      (playlists.otherPlaylists.some((p) => p.id === paramVal) ||
        playlists.schoolPlaylists.some((p) => p.id === paramVal) ||
        playlists.kidsPlaylist?.id === paramVal)
    ) {
      return paramVal
    }
    return playlists.otherPlaylists[0]?.id || null
  }, [
    playlists.kidsPlaylist?.id,
    playlists.otherPlaylists,
    playlists.schoolPlaylists,
    queryParam,
  ])

  return (
    <>
      <Dialog.Root
        open={open !== undefined}
        onOpenChange={(val) => {
          setOpen(val ? "" : null)
          invalidateQuery(getBulletinBoardItems)
        }}
        modal
      >
        <AnimatePresence>
          {open && (
            <>
              <Dialog.Portal forceMount>
                <Dialog.Content
                  forceMount
                  onPointerDownOutside={(e) => {
                    if (!e.target) return
                    if (!(e.target instanceof Element)) return
                    if (isInsideToolbar(e)) e.preventDefault()
                    const radioElement = document.querySelector("#radiobox")
                    const radioVolumeElement =
                      document.querySelector("#radioboxVolume")
                    if (radioElement && radioElement.contains(e.target)) {
                      e.preventDefault()
                    }
                    if (
                      radioVolumeElement &&
                      radioVolumeElement.contains(e.target)
                    ) {
                      e.preventDefault()
                    }
                  }}
                >
                  <Root
                    initial={{ y: -1000, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -1000, opacity: 0 }}
                    transition={{
                      ease: "backOut",
                      duration: 0.6,
                    }}
                  >
                    <UserListModalSideBar
                      {...{
                        playlists,
                        playlistId,
                        setIsNewPlaylistOpen,
                      }}
                    />
                    <Right>
                      <Suspense>
                        {queryParam ===
                        MODAL_QUERY_BULLETIN_BOARD_TYPE_VALUE ? (
                          <BulletinBoardModalContent />
                        ) : queryParam === MODAL_QUERY_SHARE_TYPE_VALUE ? (
                          <PlaylistModalShareContent grades={grades} />
                        ) : (
                          playlistId && (
                            <PlaylistModalContent
                              id={playlistId}
                              key={playlistId}
                              grades={grades}
                              onClose={() => {
                                setOpen(null)
                              }}
                              setIsNewPlaylistOpen={setIsNewPlaylistOpen}
                              setIsAddLessonOpen={setIsAddLessonOpen}
                              setMigrationModalVisibe={setMigrationModalVisibe}
                            />
                          )
                        )}
                      </Suspense>
                    </Right>
                    <Dialog.Close asChild>
                      <ModalClose>
                        <FaTimes />
                      </ModalClose>
                    </Dialog.Close>
                  </Root>
                </Dialog.Content>
                <Overlay
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                />
              </Dialog.Portal>
            </>
          )}
        </AnimatePresence>
      </Dialog.Root>
      <Dialog.Root open={isNewPlaylistOpen} onOpenChange={setIsNewPlaylistOpen}>
        {isNewPlaylistOpen && (
          <NewPlaylistModal
            onClose={(open) => {
              setIsNewPlaylistOpen(false)
            }}
          />
        )}
      </Dialog.Root>
      <Dialog.Root open={isAddLessonOpen} onOpenChange={setIsAddLessonOpen}>
        <AnimatePresence>
          {isAddLessonOpen && playlistId && (
            <AddLessonOverlay
              setIsAddLessonOpen={setIsAddLessonOpen}
              playlistId={playlistId}
            />
          )}
        </AnimatePresence>
      </Dialog.Root>
      <Dialog.Root
        open={isMigrationModalVisibe}
        onOpenChange={setMigrationModalVisibe}
      >
        {isMigrationModalVisibe && playlistId && (
          <MigratePlaylistToGroupsModal
            key={playlistId}
            playlistId={playlistId}
            onClose={() => setMigrationModalVisibe(false)}
          />
        )}
      </Dialog.Root>
    </>
  )
}

export default PlaylistModal
