import { User, UserListAccessLevel, UserListType } from "db"
import { Value } from "@stringtale/react"
import Text from "ui/Text"
import getListTypeDisplayTitle from "app/playlists/utils/getListTypeDisplayTitle"
import getFullname from "utils/users/getFullName"

export default function getListDisplayTitle(
  accessLevel: UserListAccessLevel,
  type: UserListType,
  owner?: User | null,
  groupDisplayTitle?: string
) {
  if (owner) {
    return (
      <Text>
        <Value
          name="apps.web.src.schooljaren.utils.getlistdisplaytitle.list"
          format={{
            groupTitle: groupDisplayTitle,
            hasTitle: !!groupDisplayTitle,
          }}
        >{`{hasTitle, select,
          true {Groepslijst van {groupTitle}}
          other {Groepslijst}
        }`}</Value>
        <Value
          name="apps.web.src.schooljaren.utils.getlistdisplaytitle.list"
          format={{
            name: getFullname(owner),
          }}
        >{` • Gemaakt door {name}`}</Value>
      </Text>
    )
  } else {
    return <Text>{getListTypeDisplayTitle(accessLevel, type)}</Text>
  }
}
